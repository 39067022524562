import classnames from "classnames"
import { useEffect } from "react"

// Components
import { Icon } from "@vehicles/components"
import If from "@mobi/components/If"
import { Stepper, Text, Input, Loader } from "@mobi/ds"
import Sender from "components/Sender"
import Timer from "components/Timer"
import Img from "components/Img"

// Hooks
import useVerificationCodeGeneration from "./useVerificationCodeGeneration"

// Styles
import "./index.scss"

const VerificationCodeGeneration = () => {
  const {
    onSubmit,
    onPrev,
    resendCode,
    isLoading,
    phoneSaved,
    smsStatus,
    hideBackButton,
    formProps,
  } = useVerificationCodeGeneration()

  const feedbacks = {
    error: {
      icon: "icon-warning",
      text: "código inválido, por favor verifique seu código no SMS",
      className: "notification--error",
    },
    success: {
      icon: "icon-notification",
      text: "código reenviado, verifique seu novo código no SMS",
      className: "notification--success",
    },
  }

  const {
    handleSubmit,
    formState: { isValid },
  } = formProps

  const send = (values) => {
    onSubmit(values)
  }

  useEffect(() => {
    function focusFirtsInput() {
      const codeInputName = "code-digit-0"

      const inputElement = document.getElementById(codeInputName)
      if (inputElement) {
        inputElement.autofocus = true
        inputElement.focus()
      }
    }

    focusFirtsInput()
  }, [])

  return (
    <div className="form">
      <If condition={isLoading} renderIf={<Loader />} />
      <div className="form__container">
        <div className="form__aside-image">
          <Img
            className="image"
            style={{ position: "absolute" }}
            file="flow-image"
            alt=""
            placeholder="blurred"
            role="presentation"
          />
        </div>
        <div className="form__aside-content">
          <div className="form__wrapper">
            <Stepper title="confirmação com sms" index={4} max={4} />
            <If
              condition={smsStatus}
              renderIf={
                <>
                  <div
                    className={classnames("notification", {
                      [feedbacks[smsStatus]?.className]: true,
                    })}
                    role="alert"
                  >
                    <Icon id={feedbacks[smsStatus]?.icon} />
                    <Text size="md" className="notification__text">
                      <strong>{feedbacks[smsStatus]?.text}</strong>
                    </Text>
                  </div>
                </>
              }
            />
            <Text as="h1" size="xxl">
              <strong>validação de segurança</strong>
            </Text>
            <Text size="md">
              Digite o código que enviamos por SMS para o número {phoneSaved}
            </Text>
            <form
              onSubmit={handleSubmit(send)}
              className="form__form-default"
              noValidate
            >
              <Input.Token
                name="code"
                label="código"
                size={4}
                formProps={formProps}
                shouldResetOn={null}
                inputmode="numeric"
                autocomplete="one-time-code"
                pattern="[0-9]*"
                autoCorrect="off"
              />
              <Text
                className="form__form-default-title"
                as="h2"
                weight={700}
                size="sm"
              >
                Não recebeu o SMS?
              </Text>

              <Timer
                onResendClick={() => resendCode()}
                keyToPersistExpirationTime="lastCodeSentTimestamp"
              />
              <Sender
                isNextDisabled={!isValid}
                hasPrev={!hideBackButton}
                onPrev={onPrev}
                onNext={handleSubmit(onSubmit)}
                nextLabel="avançar"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerificationCodeGeneration
