const strToNumber = (value = "") => {
  if (typeof value === "string") {
    return Number(value.replace(/\./g, ""))
  }

  return +value
}

const numberToStringWithDots = (value) =>
  (value || "")
    .toString()
    .replaceAll(".", "")
    .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".")

export { strToNumber, numberToStringWithDots }
