//Components
import { Text } from "@mobi/ds"
import VehicleDealPropertiesForm from "components/VehicleDealPropertiesForm"
import If from "@mobi/components/If"
import Loader from "components/Loader"

//Hooks
import useEditModalContent from "./useEditModalContent"

//Styles
import "./index.scss"

const EditModalContent = ({ onClose, getDeal }) => {
  const { isLoading, setIsLoading, updateLocalStep } = useEditModalContent({
    getDeal,
    onClose,
  })

  return (
    <div className="edit-modal-content">
      <If condition={isLoading} renderIf={<Loader hideLogo />} />

      <div className="step__title-group">
        <Text as="p" size="xl" weight={800}>
          Editar dados do veículo
        </Text>
      </div>

      <VehicleDealPropertiesForm
        asModalContent
        setIsLoading={setIsLoading}
        updateLocalStep={updateLocalStep}
        onCancel={onClose}
      />
    </div>
  )
}

export default EditModalContent
