import analytics from "@mobi/libraries/analytics"

// Components
import { Icon } from "@vehicles/components"
import If from "@mobi/components/If"
import { Text, Button } from "@mobi/ds"

//Hooks
import useMake from "hooks/useMake"

//Utils
import isMobile from "@mobi/utils/browser/isMobile"

const ContactCorbanDigital = ({ sellerName, sellerPhone, sellerWhatsapp }) => {
  const { onElementClicked } = useMake()

  const prepareToMakeTrack = (name, text) => {
    const webElement = {
      elementType: "button",
      location: "simulation",
      name: name,
      text: text,
    }

    onElementClicked(webElement)
  }

  const isEscale = sellerName === "Escale"
  const isRamos = sellerName === "Morais & paz"

  return (
    <>
      <div className="title">
        <Icon id="icon-success-check" />
        <If
          condition={isRamos}
          renderIf={
            <Text as="h1" size="xxl">
              <strong>
                Nossos especialistas entrarão em contato com você! Ou, se
                preferir, entre em contato conosco:
              </strong>
            </Text>
          }
          renderElse={
            <Text as="h1" size="xxl">
              <strong>
                Você está quase lá! Agora entre em contato com nossos
                especialistas
              </strong>
            </Text>
          }
        />
      </div>
      <Text className="phonenumber" as="p" size="xl" weight={800}>
        {sellerPhone}
      </Text>
      <br aria-hidden="true" />
      <If
        condition={isRamos}
        renderIf={
          <Text className="businessHours" as="p" size="xxs">
            De segunda à sexta, das 8hs às 19hs <br aria-hidden="true" />
            Aos sábados, das 8hrs às 12hrs
          </Text>
        }
        renderElse={
          <Text className="businessHours" as="p" size="xxs">
            De segunda à sexta, das 8hs às {isEscale ? "18:30hs" : "19hs"}
          </Text>
        }
      />
      <If
        condition={isMobile && !isRamos}
        renderIf={
          <>
            <br aria-hidden="true" />
            <Button
              tagName="a"
              className="--tertiary"
              leftIcon={<Icon id="icon-telephone-outlined" />}
              href={isEscale ? `tel:${"08003704545"}` : `tel:${"08000258985"}`}
              onClick={() =>
                prepareToMakeTrack(sellerName, "ligar para o especialista")
              }
            >
              ligar para o especialista
            </Button>
          </>
        }
      />
      <br aria-hidden="true" />
      <If
        condition={!isRamos}
        renderIf={
          <Button
            tagName="a"
            target="_blank"
            className="button-whatsapp --tertiary"
            leftIcon={<Icon id="icon-whatsapp-outlined" />}
            href={`https://wa.me/${sellerWhatsapp}?text=Olá,+tenho+interesse+em+saber+mais!`}
            onClick={() => {
              prepareToMakeTrack(sellerName, "Whatsapp")
              analytics.track("buttonClickGa", {
                eventName: "Whatsapp",
              })
            }}
          >
            whatsapp
          </Button>
        }
      />
      <br aria-hidden="true" />
      <Text size="md">
        Os nossos especialistas da{" "}
        <b>{isRamos ? "MP + Crédito" : sellerName}</b> estão esperando para te
        auxiliar no restante do processo e te oferecer uma{" "}
        <b>oferta personalizada</b>.
      </Text>
    </>
  )
}

export default ContactCorbanDigital
