//Components
import { Text, Button, Radio } from "@mobi/ds"

//Data
import { sortOptions } from "../../dataContent"

const SortTemplate = ({
  sortForm,
  send,
  setIsModalOpened,
  setModalContent,
}) => {
  return (
    <>
      <Text as="h2" size="lg" weight={700}>
        Ordenar por:
      </Text>
      <br />
      <form onSubmit={sortForm.handleSubmit(send)}>
        {sortOptions.map(({ value, label }) => {
          return (
            <Radio
              key={value}
              id={value}
              value={label}
              name="sort"
              label={label}
              control={sortForm.control}
            />
          )
        })}
        <br />
        <Button
          variant="secondary"
          onClick={() => {
            setIsModalOpened(false)
            setModalContent("")
          }}
          fluid
          type="button"
        >
          Cancelar
        </Button>
        <Button type="submit" fluid>
          Aplicar
        </Button>
      </form>
      <br />
    </>
  )
}

export default SortTemplate
