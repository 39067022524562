import classnames from "classnames"
import { HOMEPATH } from "gatsby-env-variables"
import { useState } from "react"

// Components
import Header from "components/Header"
import Stepper from "./stepper"
import Layout from "components/Layout"

// Utils
import getPageInfo from "utils/getPageInfo"

//Hooks
import useSimulationLayout from "hooks/useSimulationLayout"

// Styles
import "./index.scss"

const Simulation = () => {
  const { currentFormStep } = useSimulationLayout()
  const { pageTitle } = getPageInfo(currentFormStep)
  const [titleOfPageLoaded, setTitleOfPageLoaded] = useState("")

  setTimeout(() => {
    setTitleOfPageLoaded(`${pageTitle} carregado`)
  }, 100)

  return (
    <Layout
      className={classnames("--simulationpage", {
        [`--${currentFormStep}`]: currentFormStep,
      })}
      title={pageTitle}
    >
      <Header
        prevUrl={HOMEPATH}
        dynamicLogo={"itau"}
        linkLogoUrl={HOMEPATH}
        isActiveButtonAccess={false}
        mobileInfosApp={false}
      />
      <Stepper current={currentFormStep} />
      <p
        className="live-region"
        role="status"
        aria-atomic="true"
        aria-live="assertive"
      >
        {titleOfPageLoaded}
      </p>
    </Layout>
  )
}

export default Simulation
