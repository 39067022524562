// Components
import { Text, Button, Carousel, CarouselItem } from "@mobi/ds"
import { Icon } from "@vehicles/components"
import { Fragment } from "react"

// Styles
import "./index.scss"

const VehicleDetails = ({
  selectedVehicle,
  onClose,
  onNext,
  onProductClicked,
  ga,
}) => {
  const {
    dealID,
    price,
    sellerName,
    images,
    km,
    modelYear,
    equipments,
    dealText,
    gearDescription,
    manufacturingYear,
    fuelDescription,
    trimDescription,
    plateLastNumber,
    sellerCityDescription,
  } = selectedVehicle

  const prepareToAnalyticsTrack = (eventName, text) => {
    const params = {
      flow: "financing rv",
      location: "step 3",
      section: "detalhe do anúncio",
      text: text,
      items: {
        item_id: dealID,
        item_name: trimDescription,
        item_variant: manufacturingYear,
        price: price,
        currency: "BRL",
        item_category: km,
        item_category4: sellerCityDescription,
        item_list_name: "Lista de ofertas - Simulação aberta rv",
      },
    }
    ga(eventName, params)
  }

  return (
    <div className="vehicle__details">
      <Text as="h2" size="xxl" weight={800}>
        Mais detalhes
      </Text>
      <br />
      <Text as="p" size="sm" weight={400}>
        {trimDescription}
      </Text>
      <Text as="p" size="lg" weight={700}>
        {new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(price)}
      </Text>
      <Text as="p" size="sm" className="--lighter" weight={400}>
        {sellerName}
      </Text>
      <br />
      <div className="vehicle__carousel">
        <Carousel
          bullets
          arrows
          variant="slider"
          transition
          options={{
            gap: 16,
            keyboard: false,
            perView: 1,
          }}
        >
          {images.map((item) => {
            return (
              <CarouselItem key={item}>
                <img src={item} className="carousel__image" alt="" />
              </CarouselItem>
            )
          })}
        </Carousel>
      </div>
      <br />
      <br />
      <br />
      <ul className="vehicles__equipments">
        <li className="vehicles__equipments-item">
          <Text as="p" size="md" weight={400}>
            <Icon id="icon-check" />
            KM: {km}
          </Text>
        </li>
        <li className="vehicles__equipments-item">
          <Text as="p" size="md" weight={400}>
            <Icon id="icon-check" />
            Ano modelo: {modelYear}
          </Text>
        </li>
        <li className="vehicles__equipments-item">
          <Text as="p" size="md" weight={400}>
            <Icon id="icon-check" />
            Câmbio: {gearDescription}
          </Text>
        </li>
        <li className="vehicles__equipments-item">
          <Text as="p" size="md" weight={400}>
            <Icon id="icon-check" />
            Combustível: {fuelDescription}
          </Text>
        </li>
        <li className="vehicles__equipments-item">
          <Text as="p" size="md" weight={400}>
            <Icon id="icon-check" />
            Final da placa: {plateLastNumber}
          </Text>
        </li>
        <li className="vehicles__equipments-item">
          <Text as="p" size="md" weight={400}>
            <Icon id="icon-check" />
            Cidade: {sellerCityDescription}
          </Text>
        </li>
        <li className="vehicles__equipments-item">
          <Text as="p" size="md" weight={400}>
            <Icon id="icon-check" />

            {equipments.map((item, index) => {
              return (
                <Fragment key={item}>
                  {item}
                  {index + 1 === equipments.length ? "." : ", "}
                </Fragment>
              )
            })}
          </Text>
        </li>
      </ul>
      <br />
      <Text as="p" size="md" className="--lighter" weight={400}>
        Informações do veículo:
      </Text>
      <Text as="p" size="md" weight={400}>
        {dealText}
      </Text>
      <br />
      <br />
      <Button
        type="button"
        variant="secondary"
        onClick={() => {
          onClose()
          prepareToAnalyticsTrack("navigation_click", "voltar")
        }}
        fluid
      >
        voltar
      </Button>
      <Button
        type="button"
        onClick={() => {
          onClose()
          onNext(selectedVehicle)
          onProductClicked(selectedVehicle)
          prepareToAnalyticsTrack("select_content", "veja as parcelas")
        }}
        fluid
      >
        Veja as parcelas
      </Button>
      <br />
      <br />
      <br />
    </div>
  )
}

export default VehicleDetails
