import classnames from "classnames"

// Components
import If from "@mobi/components/If"
import Loader from "components/Loader"
import { Checkbox, Text, Radio, Stepper, Select, Input } from "@mobi/ds"
import Sender from "components/Sender"
import Img from "components/Img"

// Utils
import { saveOnSessionStorage, removeFromSessionStorage } from "utils/storage"

// Hooks
import useVehicleDealProperties from "./useVehicleDealProperties"
import useMake from "hooks/useMake"

// Styles
import "./index.scss"

const VehicleDealProperties = () => {
  const {
    onSearchVehicles,
    onPrev,
    onSubmit,
    setSelectedYear,
    setSelectedYearLT,
    vehiclesYears,
    isLoading,
    selectedYear,
    selectedYearLT,
    formProps,
    simulation,
  } = useVehicleDealProperties()

  const { onElementClicked } = useMake()

  const prepareToMakeTrack = (text) => {
    const webElement = {
      elementType: "button",
      location: "Vehicle Deal Properties",
      name: "hasVisited",
      text: text,
    }

    onElementClicked(webElement)
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = formProps

  const send = (values) => {
    saveOnSessionStorage({
      name: "brandModelVersion",
      value: values?.brandModelVersion,
    })
    onSubmit(values)
  }

  const getUpdatedValue = (fieldName) => watch(fieldName)

  return (
    <div className="form">
      <If condition={isLoading} renderIf={<Loader />} />

      <div className="form__container">
        <div className="form__aside-image">
          <Img
            className="image"
            style={{ position: "absolute" }}
            file="flow-image"
            alt=""
            placeholder="blurred"
            role="presentation"
          />
        </div>
        <div className="form__aside-content">
          <div className="form__wrapper">
            <Stepper title="sobre o veículo" index={2} max={4} />
            <Text as="h1" size="xxl">
              <strong>dados do veículo</strong>
            </Text>
            <Text size="lg">
              Conte-nos um pouco sobre o carro que deseja financiar:
            </Text>
            <form onSubmit={handleSubmit(send)} className="form__form-default">
              <Input.Currency
                name="vehicleValue"
                label="valor do veículo"
                control={control}
                defaultValue={simulation?.vehicleDealProperties?.vehicleValue}
                shouldResetOn={simulation?.vehicleDealProperties?.vehicleValue}
                rules={{
                  min: 5000,
                  max: 1200000,
                }}
                autoComplete="on"
              />
              <Select
                name="vehicleYear"
                label="ano modelo"
                required={true}
                minSearch={2}
                options={vehiclesYears}
                control={control}
                formProps={formProps}
                type="number"
                onChange={(yearSelected) => {
                  const value = yearSelected.value || yearSelected
                  setSelectedYear(value)
                  setSelectedYearLT("")
                  formProps.setValue("vehicleYear", value, {
                    shouldValidate: true,
                  })
                  formProps.setValue("brandModelVersion", "", {
                    shouldValidate: true,
                  })
                  formProps.setValue("vehicleId", "", {
                    shouldValidate: false,
                  })
                  formProps.setValue("isNewVehicle", false, {
                    shouldValidate: false,
                  })
                  removeFromSessionStorage({
                    name: "isNewVehicle",
                  })
                }}
                onBlur={() => {
                  const year = formProps.getValues("vehicleYear")
                  if (!year) {
                    const yearDefault = vehiclesYears[0].value
                    setSelectedYear(vehiclesYears[0])
                    formProps.setValue("vehicleYear", yearDefault, {
                      shouldValidate: true,
                    })
                    setSelectedYearLT(`${vehiclesYears[0].value} selecionado`)
                  }
                }}
              />
              <div
                className={classnames({
                  "--hidden": !selectedYear?.hasnewvehiclesavailable,
                })}
              >
                <Text size="sm"> esse veículo é zero-quilômetro?</Text>
                <Checkbox
                  name="isNewVehicle"
                  label="veículo 0 km"
                  onChange={(e) => {
                    const value = e.target.value === "false"
                    saveOnSessionStorage({
                      name: "isNewVehicle",
                      value,
                    })
                    formProps.setValue("isNewVehicle", value)
                  }}
                  control={control}
                />
              </div>
              <Select
                name="brandModelVersion"
                label="marca, modelo e versão"
                required={true}
                minSearchCharacters={2}
                isSearchingMessage="buscando..."
                fetchOptions={onSearchVehicles}
                openOnFocus={false}
                onFocus={() => {
                  formProps.setValue("vehicleId", "", {
                    shouldValidate: false,
                  })
                }}
                onSelectItem={(item) => {
                  formProps.setValue("vehicleId", item.id, {
                    shouldValidate: true,
                  })

                  formProps.setValue("brandModelVersion", item.value, {
                    shouldValidate: true,
                  })

                  saveOnSessionStorage({
                    name: "brandModelVersion",
                    value: item.value,
                    isNewVehicle: getUpdatedValue("isNewVehicle"),
                  })

                  // const nextElement = document.querySelector("input#new")
                  // setTimeout(() => {
                  //   nextElement && nextElement.focus()
                  // }, 300)
                }}
                control={control}
                formProps={formProps}
              />
              <fieldset>
                <Text as="legend" size="xl" weight="700">
                  você já tem uma data para realizar esse sonho?
                </Text>
                {/* TODO: transferir isso para um arquivo data */}
                {[
                  {
                    id: "oneweek",
                    value: "ONE_WEEK",
                    text: "em 1 semana",
                  },
                  {
                    id: "fifteen",
                    value: "FIFTEEN_DAYS",
                    text: "em 15 dias",
                  },
                  {
                    id: "onemonth",
                    value: "ONE_MONTH",
                    text: "em 1 mês",
                  },
                  {
                    id: "idk",
                    value: "NOT_SURE",
                    text: "estou só simulando",
                  },
                ].map(({ text, id, value }) => {
                  return (
                    <Radio
                      key={id}
                      id={id}
                      value={value}
                      name="purchasePeriod"
                      label={text}
                      required={true}
                      control={control}
                    />
                  )
                })}
              </fieldset>
              <fieldset>
                <Text as="legend" size="xl" weight="700">
                  você já visitou o carro?{" "}
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>
                    (opcional)
                  </span>
                </Text>
                <Radio
                  name="hasVisited"
                  label="sim"
                  value="sim"
                  required={false}
                  control={control}
                  onChange={(e) => {
                    prepareToMakeTrack("sim")
                    formProps.setValue("hasVisited", e.target.value)
                  }}
                />
                <Radio
                  name="hasVisited"
                  label="não"
                  value="nao"
                  required={false}
                  control={control}
                  onChange={(e) => {
                    prepareToMakeTrack("não")
                    formProps.setValue("hasVisited", e.target.value)
                  }}
                />
              </fieldset>

              <Sender
                isNextDisabled={!isValid}
                hasPrev={true}
                onPrev={onPrev}
                onNext={handleSubmit(onSubmit)}
                nextLabel="avançar"
              />
            </form>
          </div>
        </div>
      </div>

      <p
        className="live-region"
        role="status"
        aria-atomic="true"
        aria-live="assertive"
      >
        {selectedYearLT}
      </p>
    </div>
  )
}

export default VehicleDealProperties
