//Components
import EditModalContent from "../EditModalContent"

const FormTemplate = ({ onCloseModal, setIsLoadingPlaceholder, getDeal }) => {
  return (
    <EditModalContent
      onClose={onCloseModal}
      getDeal={() => {
        setIsLoadingPlaceholder(true)
        getDeal()
      }}
    />
  )
}

export default FormTemplate
