//Utils
import { getCookies, getUrlParamAll } from "./browser"

const getTrackingData = () =>
  new Promise((resolve) => {
    let timer
    const resolveData = () => {
      const currentParams = getUrlParamAll()
      const { _fbp, _ga, ...cookies } = getCookies()
      const anonymousId = window?._Cohesion?.anonymousId
      const sourceUrl = window?.location?.href

      resolve({
        ...currentParams,
        ...cookies,
        anonymousId,
        sourceUrl,
        facebookClickId: currentParams?.fbclid,
        googleClientId: currentParams?.gclid,
        facebookBrowserId: _fbp,
        googleGaCookie: _ga,
      })
    }

    if (window?.__Cohesion?.initialized) {
      clearTimeout(timer)
      resolveData()
    }

    setTimeout(() => {
      resolve({})
    }, 2000)
  })

export default getTrackingData
