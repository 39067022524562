import { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"

// Services
import entities from "@entities"
import services from "services"

// Utils
import { getUUIDFromUrl, resetFocus } from "utils/browser"
import { fillField } from "utils/forms"
import { toInstallmentsList } from "services/mappers"
import { currencyToFloat } from "@mobi/utils/formatters/currency"
import analytics from "@mobi/libraries/analytics"
import getQueryStringFromObject from "utils/getQueryStringFromObject"

//Hooks
import useMake from "hooks/useMake"

const useSimulation = () => {
  const [stepData, setStepData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [simulationSuccess, setSimulationSuccess] = useState(false)
  const [isFetchingSimulation, setIsFetchingSimulation] = useState(false)
  const { onFormSubmitted, onFormUpdated } = useMake()

  const [initialValues] = useState({
    numberOfInstallments: "",
    installments: "",
    downPayment: "",
    shouldSendToLead: false,
  })

  const uuid = getUUIDFromUrl()

  const formProps = useForm({
    mode: "onChange",
    defaultValues: initialValues,
  })

  useEffect(() => {
    const startSimulationStep = async () => {
      const sourceUrl = window?.location?.href

      await services.simulation.update({}, { sourceUrl })

      const data = await services.simulation.getSimulation()
      if (data) {
        const isAbleToProceed = data?.isAbleToProceed
        const initialInstallment = data?.availableInstallmentOptions.find(
          (item) => item.suggested
        )
        const isDealer = data?.lead?.isSellerSelected

        if (isAbleToProceed) {
          const eventName = isDealer
            ? "ResultadoSimulacaoLoja"
            : "ResultadoSimulacaoDiretoDono"
          analytics.track("pageLoad", {
            pageName: eventName,
            custom: {
              parceiro: isDealer ? "Loja" : data.displayName,
              jurosgiro: `${initialInstallment?.monthlyInterest}%`,
              parc: initialInstallment?.value,
              vep: data.defaultDownPaymentRaw,
            },
          })

          onFormSubmitted(isAbleToProceed)

          onReceiveSimulation({
            data,
            formProps,
            setIsLoading,
            setStepData,
          })

          resetFocus()
          return setIsLoading(false)
        } else {
          const query = getQueryStringFromObject({
            uuid: uuid.value,
            error: 1,
          })
          onFormSubmitted(isAbleToProceed)
          analytics.track(
            "pageLoad",
            { pageName: "CpfNegado" },
            {
              parceiro: isDealer ? "Loja" : data.displayName,
              jurosgiro: `${initialInstallment?.monthlyInterest}%`,
              parc: initialInstallment?.value,
            }
          )

          return navigate(`/formulario/erro/${query}`)
        }
      } else {
        onFormSubmitted(false)
        return navigate(`/formulario/erro/?error=2`)
      }
    }

    startSimulationStep()
  }, [])

  const requestNewSimulation = async (downPayment, installments) => {
    formProps.setValue("shouldSendToLead", false)
    const newPayload = {
      ...formProps.getValues(),
      numberOfInstallments:
        installments || formProps.getValues()?.numberOfInstallments,
      downPayment: currencyToFloat(downPayment),
    }
    setIsFetchingSimulation(true)
    const response = await requestSimulation({
      payload: newPayload,
      uuid,
      setSimulationSuccess,
      setIsLoading,
    })

    if (response?.status === 200) {
      const { data } = response
      if (data && data.simulationOptions) {
        onFormUpdated()
        setIsFetchingSimulation(false)
        onReceiveSimulation({
          setIsLoading,
          setStepData,
          data,
          formProps,
          stepData,
          updateCurrentSimulation: true,
        })
      }
    } else {
      setIsFetchingSimulation(false)
    }
  }

  const onSubmit = async (payload) => {
    setIsLoading(true)
    setIsFetchingSimulation(true)
    const response = await requestSimulation({
      payload,
      setSimulationSuccess,
      setIsLoading,
    })

    if (response?.status === 200) {
      const shouldSendToLead = formProps.getValues()?.shouldSendToLead
      setIsFetchingSimulation(false)
      shouldSendToLead && setSimulationSuccess(true)
      if (!shouldSendToLead) {
        response?.data &&
          onReceiveSimulation({
            data: response.data,
            formProps,
            setIsLoading,
            setStepData,
            stepData,
            updateCurrentSimulation: true,
          })
      }
    } else {
      setIsFetchingSimulation(false)
    }
  }

  return {
    formProps,
    isLoading,
    isFetchingSimulation,
    initialValues,
    stepData,
    simulationSuccess,
    setIsLoading,
    setSimulationSuccess,
    onSubmit,
    requestNewSimulation,
    getSelectedOption: ({ numberOfInstallments, installments = [] }) => {
      return installments.find((item) => {
        if (item.value === parseInt(numberOfInstallments)) {
          return item
        }
        return false
      })
    },
  }
}

const requestSimulation = async ({ payload, setIsLoading }) => {
  const response = await services.simulation.update(
    payload,
    { sourceUrl: window?.location?.href },
    true
  )

  if (response?.status === 200) {
    const mountSimulationOptions = {
      ...response.data.simulation,
      simulationInstallments: response.data.simulationInstallments,
    }
    const newPayload = {
      ...response,
      data: {
        ...response.data,
        simulationOptions: entities.SimulationOptions(mountSimulationOptions),
      },
    }

    setIsLoading(false)
    return newPayload
  } else {
    setIsLoading(false)
  }
}

const onReceiveSimulation = ({
  data = {},
  formProps,
  setStepData,
  stepData,
  updateCurrentSimulation = false,
}) => {
  const newStepData = updateCurrentSimulation ? data?.simulationOptions : data
  const installments = newStepData?.availableInstallmentOptions
    ? toInstallmentsList(newStepData?.availableInstallmentOptions)
    : []

  const hasSelectedParcel = !!newStepData.availableInstallmentOptions.find(
    (element) => element.selected
  )

  const initialInstallment = installments.find((item) => {
    return hasSelectedParcel ? item.selected : item.suggested
  })

  fillField({
    fieldName: "downPayment",
    value: newStepData?.defaultDownPaymentRaw,
    formProps,
  })
  fillField({
    fieldName: "numberOfInstallments",
    value: initialInstallment?.value,
    formProps,
  })
  fillField({
    fieldName: "installments",
    value: initialInstallment?.label,
    formProps,
  })
  setStepData({
    ...stepData,
    ...newStepData,
  })
  return data
}

export default useSimulation
