import { useState, useEffect, useContext } from "react"
import analytics from "@mobi/libraries/analytics"
import { useForm } from "react-hook-form"

// Services
import services from "services"

// Utils
import { getUUIDFromUrl } from "utils/browser"
import { fillForm } from "utils/forms"
import { resetFocus } from "utils/browser"
import { storage } from "utils/storage"

// Contexts
import { Context } from "contexts/simulation"
import { useStep } from "@mobi/libraries/step"

//Hooks
import useMake from "hooks/useMake"

const useSeller = () => {
  const { next, prev } = useStep()
  const { onFormContinued } = useMake()
  const { simulation, setSimulation } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [cities, setCities] = useState([])
  const [brandModelVersion, setBrandModelVersion] = useState("")
  const [isNewVehicle, setIsNewVehicle] = useState("")
  const [neighborhoods, setNeighborhoods] = useState([])
  const [sellers, setSellers] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [sellersPagination, setSellersPagination] = useState({})
  const [activeDescendant, setActiveDescendant] = useState()
  const [initialValues] = useState({
    sellerUUID: "",
    notsure: null,
    displayName: "",
    state: "",
    city: "",
    neighborhood: "",
    searchByNeighborhood: "nao",
  })

  const uuid = getUUIDFromUrl()
  const formProps = useForm({
    mode: "onChange",
    defaultValues: initialValues,
  })

  useEffect(() => {
    resetFocus()
    setIsLoading(true)

    const vehicleBrand = storage.session.getItem("brandModelVersion")
    vehicleBrand && setBrandModelVersion(vehicleBrand)

    const isNewVehicle = storage.session.getItem("isNewVehicle")
    setIsNewVehicle(isNewVehicle)

    const getSimulation = async () => {
      const response = await services.sellers.getSimulation()

      if (response) {
        fillForm({
          simulation: { ...response?.seller },
          formProps,
        })
        setSimulation(response)
        setIsLoading(false)
        return response
      }

      setIsLoading(false)
    }

    getSimulation()
    analytics.track("pageLoad", { pageName: "LojaOuConcessionaria" })
  }, [])

  const selectSeller = (sellerUUID, displayName) => {
    formProps.setValue("sellerUUID", sellerUUID, {
      shouldValidate: true,
    })
    formProps.setValue("displayName", displayName, {
      shouldValidate: true,
    })
  }

  const prepareToMakeTrack = () => {
    const formValues = formProps.getValues()
    const fieldsInfo = Object.entries(formValues).filter((values) => {
      if (values[1]) {
        return values
      }
    })
    const stepContext = {
      stepName: "seller",
      stepNumber: 4,
    }
    onFormContinued(fieldsInfo, stepContext)
  }

  const updateLocalStep = ({ updatedData }) => {
    setIsLoading(false)
    const nextStep = updatedData?.nextFormStep
    setSimulation({
      ...updatedData,
      currentFormStep: nextStep,
    })
    next(nextStep)
    setIsLoading(false)

    prepareToMakeTrack()
    analytics.track("buttonClickGa", { eventName: "Proximo" })
  }

  const onSubmit = async (payload) => {
    setIsLoading(true)

    const updatedSimulation = await services.sellers.update(payload, uuid)

    if (updatedSimulation.status === 200) {
      updateLocalStep({ updatedData: updatedSimulation.data })
      return updatedSimulation.data
    }

    setIsLoading(false)
  }

  const onSearchSellers = async ({
    page = 0,
    limit = 40,
    neighborhood = "",
    state = "",
    city = "",
    term = "",
    brandModelVersion = "",
    isNewVehicle,
  }) => {
    const isSomeNeighborhood =
      neighborhood && neighborhood !== "TODOS OS BAIRROS"

    const response = await services.sellers.getSellers({
      page,
      limit,
      displayName: term,
      isNewVehicle: !!isNewVehicle,
      ...(city && { city }),
      ...(brandModelVersion && { brandModelVersion }),
      ...(state && { state }),
      ...(isSomeNeighborhood && { neighborhood }),
    })

    if (response) {
      const { sellers, ...props } = response
      setSellers(sellers)
      setSellersPagination(props)
      return sellers
    }
  }

  const onSearchCities = async (state, filters) => {
    const response = await services.places.getCities(state, filters)

    if (response) return response
  }

  const onSearchNeighborhoods = async (state, city, filters) => {
    const response = await services.places.getNeighborhoods(
      state,
      city,
      filters
    )

    if (response) return response
  }

  const onKeyDown = ({ target, key, keyCode, selected }) => {
    if (key === "Enter" || keyCode === 32) {
      selectSeller(selected.sellerUUID, selected.displayName)
    } else if (key === "ArrowDown" || keyCode === 40) {
      target.nextSibling?.focus()
    } else if (key === "ArrowUp" || keyCode === 38) {
      target.previousSibling?.focus()
    }
  }

  const onPrev = () => {
    const newSimulationOnPrev = {
      ...simulation,
      nextFormStep: simulation.currentFormStep,
      currentFormStep: simulation.previousFormStep,
    }
    setSimulation(newSimulationOnPrev)
    prev(simulation?.previousFormStep)
    analytics.track("buttonClickGa", { eventName: "Voltar" })
  }

  return {
    cities,
    neighborhoods,
    isLoading,
    initialValues,
    sellers,
    currentPage,
    sellersPagination,
    brandModelVersion,
    isNewVehicle,
    formProps,
    activeDescendant,
    selectSeller,
    setSellersPagination,
    setCurrentPage,
    setSellers,
    setIsLoading,
    setCities,
    setNeighborhoods,
    onSubmit,
    onSearchSellers,
    onSearchCities,
    onSearchNeighborhoods,
    onKeyDown,
    onPrev,
    setActiveDescendant,
  }
}

export default useSeller
