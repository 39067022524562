//Components
import { Input, Select, Radio, Text, If } from "@mobi/ds"
import Sender from "components/Sender"

//Hooks
import useVehicleDealPropertiesForm from "./useVehicleDealPropertiesForm"

// Utils
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"

//Style
import "./index.scss"

//Icon
import SearchIcon from "images/icons/icon-search.svg"

const VehicleDealPropertiesForm = ({
  setIsLoading,
  updateLocalStep,
  onCancel,
  asModalContent = false,
}) => {
  const {
    onSearchCities,
    vehiclesYears,
    formProps,
    simulation,
    setSimulation,
    onSubmit,
    brands,
    models,
    isModelsLoading,
    brand,
  } = useVehicleDealPropertiesForm({
    setIsLoading,
    asModalContent,
    updateLocalStep,
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = formProps

  return (
    <form className="step__form step__form--vehicle-deal-properties">
      <div className="step__vehicle-value-row">
        <Input.Currency
          name="initialPrice"
          label="Valor mínimo (Opcional)"
          autoComplete="no"
          control={control}
          defaultValue={simulation?.vehicleDealProperties?.initialPrice}
          shouldResetOn={simulation?.vehicleDealProperties?.initialPrice}
          rules={{
            max: 1200000,
          }}
          onChange={(value) => {
            if (!asModalContent) {
              saveOnSessionStorage({
                name: "initialPrice",
                value: value,
              })
            }
          }}
          onBlur={(initialPrice) => {
            const finalPrice = formProps.getValues("finalPrice")

            if (initialPrice && finalPrice && initialPrice > finalPrice) {
              setSimulation({
                ...simulation,
                ...{
                  vehicleDealProperties: {
                    ...simulation.vehicleDealProperties,
                    initialPrice: finalPrice,
                  },
                },
              })

              formProps.setValue("initialPrice", finalPrice, {
                shouldValidate: true,
              })

              if (!asModalContent) {
                saveOnSessionStorage({
                  name: "initialPrice",
                  value: finalPrice,
                })
              }
            }
          }}
          required={false}
        />
        <Input.Currency
          name="finalPrice"
          label="Valor máximo"
          autoComplete="no"
          defaultValue={simulation?.vehicleDealProperties?.finalPrice}
          shouldResetOn={simulation?.vehicleDealProperties?.finalPrice}
          required={true}
          rules={{
            min: 5000,
            max: 1200000,
          }}
          onChange={(value) => {
            if (!asModalContent) {
              saveOnSessionStorage({
                name: "finalPrice",
                value: value,
              })
            }
          }}
          onBlur={(finalPrice) => {
            const initialPrice = formProps.getValues("initialPrice")

            if (initialPrice && finalPrice && initialPrice > finalPrice) {
              setSimulation({
                ...simulation,
                ...{
                  vehicleDealProperties: {
                    ...simulation.vehicleDealProperties,
                    finalPrice: initialPrice,
                  },
                },
              })

              formProps.setValue("finalPrice", initialPrice, {
                shouldValidate: true,
              })

              if (!asModalContent) {
                saveOnSessionStorage({
                  name: "finalPrice",
                  value: initialPrice,
                })
              }
            } else if (!finalPrice) {
              formProps.setValue("finalPrice", "", {
                shouldValidate: true,
              })
            }
          }}
          control={control}
        />
      </div>
      <div className="step__vehicle-year-row">
        <Select
          className="step__year step__year--vehicle-deal-properties"
          name="initialYear"
          label="Ano mínimo"
          minSearch={2}
          options={vehiclesYears}
          onBlur={() => {
            const initialYear = formProps.getValues("initialYear")
            const initialYearSession = getFromSessionStorage({
              name: "initialYear",
            })

            if (!initialYear) {
              const finalYear = formProps.getValues("finalYear")
              const initialYearDefault =
                initialYearSession || finalYear || new Date().getFullYear()
              formProps.setValue("initialYear", initialYearDefault, {
                shouldValidate: true,
              })
            }
          }}
          onSelectItem={({ value }) => {
            let initialYear = value
            const finalYear = formProps.getValues("finalYear")

            if (initialYear && finalYear && initialYear > finalYear) {
              initialYear = finalYear
              formProps.setValue("initialYear", initialYear, {
                shouldValidate: true,
              })
            }

            if (!asModalContent) {
              saveOnSessionStorage({
                name: "initialYear",
                value: initialYear,
              })
            }
          }}
          required={true}
          control={control}
          formProps={formProps}
        />
        <Select
          className="step__year step__year--vehicle-deal-properties"
          name="finalYear"
          label="Ano máximo"
          minSearch={2}
          options={vehiclesYears}
          onBlur={() => {
            const finalYear = formProps.getValues("finalYear")
            const finalYearSession = getFromSessionStorage({
              name: "finalYear",
            })

            if (!finalYear) {
              const finalYearDefault =
                finalYearSession || new Date().getFullYear()
              formProps.setValue("finalYear", finalYearDefault, {
                shouldValidate: true,
              })
            }
          }}
          required={true}
          control={control}
          formProps={formProps}
          onSelectItem={({ value }) => {
            let finalYear = value
            const initialYear = formProps.getValues("initialYear")

            if (initialYear && finalYear && initialYear > finalYear) {
              finalYear = initialYear
              formProps.setValue("finalYear", finalYear, {
                shouldValidate: true,
              })
            }

            if (!asModalContent) {
              saveOnSessionStorage({
                name: "finalYear",
                value: finalYear,
              })
            }
          }}
        />
      </div>
      <Select
        name="brand"
        label="Marca"
        required={true}
        options={brands}
        onSelectItem={({ value, label }) => {
          const newBrandValue = { id: value, name: label }

          formProps.setValue("model", null)

          formProps.setValue("brand", newBrandValue, {
            shouldValidate: true,
          })

          saveOnSessionStorage({
            name: "brand",
            value: newBrandValue,
          })

          const nextElement = document.querySelector("input#ds-field-model")

          setTimeout(() => {
            nextElement && nextElement.focus()
          }, 200)
        }}
        getInputValue={(fieldValue) => fieldValue?.name}
        control={control}
        formProps={formProps}
        icon={<SearchIcon />}
        placeholder={"digite uma marca"}
      />
      <Select
        name="model"
        label="Modelo (Opcional)"
        required={false}
        options={models}
        onSelectItem={({ value, label }) => {
          const newModelValue = { id: value, name: label }

          formProps.setValue("model", newModelValue, {
            shouldValidate: true,
          })

          saveOnSessionStorage({
            name: "model",
            value: newModelValue,
          })

          const nextElement = document.querySelector("input#ds-field-dealCity")
          setTimeout(() => {
            nextElement && nextElement.focus()
          }, 200)
        }}
        getInputValue={(fieldValue) => fieldValue?.name}
        control={control}
        formProps={formProps}
        icon={<SearchIcon />}
        disabled={!brand?.id}
        isLoading={isModelsLoading}
      />
      <Select
        className="step__select-city"
        name="dealCity"
        minSearchCharacters={2}
        isSearchingMessage="buscando..."
        label="Pesquisar em qual cidade? (Opcional)"
        required={false}
        fetchOptions={onSearchCities}
        onSelectItem={({ value, state }) => {
          formProps.setValue("dealCity", value, {
            shouldValidate: true,
          })
          formProps.setValue("dealState", state, {
            shouldValidate: true,
          })
        }}
        getInputValue={(fieldValue) => {
          const state = formProps.getValues("dealState")

          if (!state) {
            return fieldValue
          }

          return `${fieldValue}, ${state}`
        }}
        control={control}
        formProps={formProps}
        icon={<SearchIcon />}
      />
      <If
        condition={!asModalContent}
        renderIf={
          <fieldset className="step__purchase-period step__purchase-period--vehicle-deal-properties">
            <br />
            <Text size="xl" weight="800">
              Você já tem uma data para realizar esse sonho?
            </Text>
            {/* TODO: Transferir isso aqui para um data */}
            {[
              {
                id: "oneweek",
                value: "ONE_WEEK",
                text: "em 1 semana",
              },
              {
                id: "fifteen",
                value: "FIFTEEN_DAYS",
                text: "em 15 dias",
              },
              {
                id: "onemonth",
                value: "ONE_MONTH",
                text: "em 1 mês",
              },
              {
                id: "idk",
                value: "NOT_SURE",
                text: "estou só simulando",
              },
            ].map(({ text, id, value }) => {
              return (
                <Radio
                  key={id}
                  id={id}
                  value={value}
                  name="purchasePeriod"
                  label={text}
                  required={true}
                  control={control}
                />
              )
            })}
          </fieldset>
        }
      />
      <Sender
        isNextDisabled={!isValid}
        onNext={handleSubmit(onSubmit)}
        nextLabel={asModalContent ? "Salvar" : "Avançar"}
        hasPrev={asModalContent}
        onPrev={onCancel}
        prevLabel={asModalContent ? "Cancelar" : "Voltar"}
      />
      <br />
      <br />
    </form>
  )
}

export default VehicleDealPropertiesForm
