import { useState, useEffect, useContext } from "react"
import analytics from "@mobi/libraries/analytics"
import { useForm } from "react-hook-form"

// Services
import services from "services"
import { updateToken } from "services/auth"

// Utils
import { getFromSessionStorage, storage } from "utils/storage"
import { getUUIDFromUrl, resetFocus, getUrlParamAll } from "utils/browser"
import { hiddenPhoneMask } from "@vehicles/utils/formatters/phone"

// Contexts
import { Context } from "contexts/simulation"
import { useAlert } from "@mobi/ds"
import { useStep } from "@mobi/libraries/step"

//Hooks
import useMake from "hooks/useMake"

// Components
import { Icon } from "@vehicles/components"

const useVerificationCodeGeneration = () => {
  const { next, prev } = useStep()
  const { onFormContinued } = useMake()
  const { simulation, setSimulation } = useContext(Context)
  const Alert = useAlert()
  const [isLoading, setIsLoading] = useState(true)
  const [smsStatus, setSmsStatus] = useState(false)

  const phoneRaw = getFromSessionStorage({ name: "phone" })
  const phoneSaved = hiddenPhoneMask(phoneRaw)

  const hideBackButton = getUrlParamAll()?.origin === "email"

  const uuid = getUUIDFromUrl()

  const formProps = useForm({
    mode: "onChange",
    defaultValues: {
      notifyMethod: "",
      code: "",
    },
  })

  useEffect(() => {
    resetFocus()

    const getSimulation = async () => {
      const updatedSimulation = await services.verificationCode
        .getSimulation()
        .catch(() => {
          setIsLoading(false)
          return
        })

      setSimulation({ ...simulation, ...updatedSimulation })
      setIsLoading(false)
    }

    getSimulation()
    sendCode({})

    analytics.track("pageLoad", { pageName: "ValidacaoDeSegurança" })
  }, [])

  const saveLastCodeSent = () => {
    const register = new Date()
    storage.session.setItem("lastCodeSent", register)
  }

  const sendCode = async ({ resend = false }) => {
    setIsLoading(true)

    const simulation = await services.verificationCode
      .updateGeneration(
        { notifyMethod: "SMS" },
        { sourceUrl: window?.location?.href },
        uuid
      )
      .catch(() => {
        setIsLoading(false)
        return
      })

    if (simulation?.data) {
      saveLastCodeSent()
      updateToken(simulation?.data?.auth)
      setIsLoading(false)

      if (resend) {
        setSmsStatus("success")
        formProps.setValue("code", "")
        Alert?.success("código reenviado, verifique seu novo código no SMS", {
          icon: <Icon id={"icon-outlined-notification"} />,
        })
      }
    }
  }

  return {
    hideBackButton,
    isLoading,
    phoneSaved,
    smsStatus,
    formProps,
    resendCode: () =>
      sendCode({
        resend: true,
      }),
    onSubmit: async (payload) => {
      setIsLoading(true)
      setSmsStatus(false)

      const confirmation = await services.verificationCode
        .updateConfirmation(
          { ...payload, notifyMethod: "SMS" },
          { sourceUrl: window?.location?.href },
          uuid
        )
        .catch((err) => {
          Alert?.error(
            "código inválido, por favor verifique seu código no SMS",
            {
              icon: <Icon id={"icon-outlined-warning"} />,
            }
          )
          setSmsStatus("error")
          setIsLoading(false)
          return err
        })

      if (confirmation.data) {
        updateToken(confirmation?.data?.auth)
        const nextStep = "simulation"
        setSimulation({
          ...simulation,
          ...confirmation.data,
          currentFormStep: nextStep,
        })
        next(nextStep)

        const stepContext = {
          stepName: "verificationCodeConfirmation",
          stepNumber: 5,
        }
        onFormContinued(null, stepContext)
        analytics.track("buttonClickGa", { eventName: "Simular" })
      }
    },
    onPrev: () => {
      const newSimulationOnPrev = {
        ...simulation,
        nextFormStep: simulation.currentFormStep,
        currentFormStep: simulation.previousFormStep,
      }
      setSimulation(newSimulationOnPrev)
      prev(simulation?.previousFormStep)
    },
  }
}

export default useVerificationCodeGeneration
