// Components
import { Text } from "@mobi/ds"

// Styles
import "./index.scss"

import CarSvg from "images/car-illustration.svg"

const Panel = () => {
  const CarIllustration = () => <CarSvg className="image" role="presentation" />

  return (
    <div className="panel">
      <Text as="h1" size="xxl" weight="900">
        vamos começar nos conhecendo ;)
      </Text>
      <CarIllustration />
    </div>
  )
}

export default Panel
