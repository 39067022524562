export const marketplaceCardItems = [
  {
    file: "emprestimo-image",
    url: "https://emprestimo.itau.com.br/?utm_sou=veiculos&utm_med=cross-sell&utm_camp=marketplace_ls",
    title: "Os melhores empréstimos e produtos para você",
    paragraph:
      "Empréstimos e produtos exclusivos pré-aprovados para você. Acesse e confira, a gente compara e mostra sua melhor oferta. Sujeito à análise.",
    button: "Confira agora",
  },
  // {
  //   file: "marketplace-tag-itau",
  //   url: "https://tag.itau.com.br/rd_xVRY",
  //   title: "Tag Itaú",
  //   paragraph:
  //     "Cliente com cartão de crédito ou débito Itaú também tem benefício exclusivo para adquirir sua tag de pedágios, passe sem limites em pedágios e estacionamentos, livre de mensalidades para sempre!!",
  //   button: "Quero uma tag",
  // },
  {
    file: "open-account",
    url: "https://www.itau.com.br/contas/conta-corrente/?utm_source=st&utm_medium=nd&utm_campaign=st-conv-nd-st-nd-all&utm_content=nd-nd-nd-iacnta-nd-pfaqu-MT00001342-rvproduto&utm_term=veiculos-x-status-marketplace&utm_sou=veiculos&utm_med=x&utm_camp=status&utm_cont=marketplace&utm_ter=25",
    title: "Abertura de contas",
    paragraph:
      "Ainda não é Itaú? Abra sua conta sem sair de casa e aproveite os benefícios do banco que tem tudo pra você!",
    button: "Abra sua conta",
  },
  {
    file: "click-image",
    url: "https://cartoes.itau.com.br/formulario/?tracker=0&step=0&card=123&utm_sou=veiculos&utm_med=cross-sell&utm_camp=marketplace_ls",
    title: "Cartão de crédito Itaú Click Platinum",
    paragraph:
      "Peça agora seu cartão Click do Itaú, um cartão livre de anuidades e com limite de até R$10.000 ! Utilizando seu Click, você tem Tag Itaú livre de mensalidade pra sempre e muitos outros benefícios. Sujeito à análise.",
    button: "Peça já o seu",
  },
]
