import { useState, useEffect, useContext } from "react"
import analytics from "@mobi/libraries/analytics"
import { useForm } from "react-hook-form"

// Services
import services from "services"

// Utils
import { fillForm } from "utils/forms"
import { getUUIDFromUrl, resetFocus } from "utils/browser"

// Contexts
import { Context } from "contexts/simulation"
import { useStep } from "@mobi/libraries/step"

//Hooks
import useMake from "hooks/useMake"

const useVehicleDealProperties = () => {
  const { next, prev } = useStep()
  const { onFormContinued } = useMake()
  const { setSimulation, simulation } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [vehiclesYears, setVehiclesYears] = useState([])
  const [selectedYear, setSelectedYear] = useState({
    value: new Date().getFullYear(),
    hasnewvehiclesavailable: true,
  })
  const [selectedYearLT, setSelectedYearLT] = useState()

  const formProps = useForm({
    mode: "onChange",
    defaultValues: {
      vehicleValue: null,
      vehicleYear: new Date().getFullYear(),
      isNewVehicle: false,
      vehicleId: "",
      brandModelVersion: "",
      purchaseType: "DEALER",
      purchasePeriod: "",
    },
  })

  useEffect(() => {
    setIsLoading(true)
    resetFocus()

    const getSimulation = async () => {
      const response = await services.vehicles.getSimulation().catch(() => {
        setIsLoading(false)
        return
      })
      const responseVehicles = response?.vehicleDealProperties

      const responseYears = await getYears({ setVehiclesYears }).catch(() => {
        setIsLoading(false)
      })

      if (responseVehicles) {
        const selectedIndex = responseYears.findIndex((item) => {
          return (
            item.value === (responseVehicles.vehicleYear || selectedYear?.value)
          )
        })
        selectedIndex && setSelectedYear(responseYears[selectedIndex])

        fillForm({
          simulation: {
            ...responseVehicles,
          },
          formProps,
        })
        setIsLoading(false)
        setSimulation(response)
      }

      setIsLoading(false)
      return responseVehicles
    }

    getSimulation()
    analytics.track("pageLoad", { pageName: "DadosDoVeiculo" })
  }, [])

  const onSearchVehicles = ({ search }) => {
    const uuid = getUUIDFromUrl()
    const year = formProps.getValues().vehicleYear
    return services.vehicles.getVehicles({
      year: year,
      term: search,
      ...(uuid &&
        uuid.name && {
          [uuid.name]: uuid.value,
        }),
    })
  }

  const prepareToMakeTrack = () => {
    const formValues = formProps.getValues()
    const fieldsInfo = Object.entries(formValues).filter((values) => {
      if (values[1]) {
        return values
      }
    })
    const stepContext = {
      stepName: "vehicleDealProperties",
      stepNumber: 3,
    }
    onFormContinued(fieldsInfo, stepContext)
  }

  const onSubmit = async (payload) => {
    setIsLoading(true)

    const updatedSimulation = await services.vehicles
      .update(payload, { sourceUrl: window?.location?.href })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })

    if (updatedSimulation.status === 200) {
      const data = updatedSimulation?.data
      const nextStep = data?.nextFormStep
      setSimulation({
        ...data,
        currentFormStep: nextStep,
      })
      next(nextStep)

      prepareToMakeTrack()
      analytics.track("buttonClickGa", { eventName: "ContinuarSimulacao" })

      return data
    }
  }

  const onPrev = () => {
    const newSimulationOnPrev = {
      ...simulation,
      nextFormStep: simulation.currentFormStep,
      currentFormStep: simulation.previousFormStep,
    }
    setSimulation(newSimulationOnPrev)
    prev(simulation?.previousFormStep)
    analytics.track("buttonClickGa", { eventName: "Voltar" })
  }

  return {
    setIsLoading,
    setSelectedYear,
    setSelectedYearLT,
    onSearchVehicles,
    onSubmit,
    onPrev,
    selectedYear,
    isLoading,
    formProps,
    simulation,
    vehiclesYears,
    selectedYearLT,
  }
}

const getYears = async ({ setVehiclesYears }) => {
  const uuid = getUUIDFromUrl()
  if (!uuid) return Promise.reject()
  const response = await services.vehicles.getYears({ [uuid.name]: uuid.value })

  const years = response
  const filteredYears = years.filter(
    (item, index, acc) => acc.findIndex((t) => t.value === item.value) === index
  )
  setVehiclesYears(filteredYears)
  return years
}

export default useVehicleDealProperties
