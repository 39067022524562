import InputRange from "react-input-range"

//Components
import { Text, Button, Select, Input, Checkbox, Link } from "@mobi/ds"
import If from "@mobi/components/If"

//Utils
import { strToNumber } from "@vehicles/utils/formatters/number"

const FilterTemplate = ({
  asModalContent,
  filterForm,
  handleOnClearFilters,
  formatKmInputValue,
  searchDeals,
  hasUsedFilter,
  mapFormFiltersToSearch,
  setIsModalOpened,
  setModalContent,
  setIsLoadingPlaceholder,
  onKmRange,
  onSearchCities,
  onCloseModal,
}) => {
  const km = filterForm.watch("kilometers")
  const finalPrice = filterForm.watch("finalPrice")
  const initialPrice = filterForm.watch("initialPrice")
  const isNewVehicle = filterForm.watch("isNewVehicle")
  const kmValue = strToNumber(km)

  const setFinalPlate = (e, index) => {
    const allChecked = filterForm
      .getValues()
      .plateFinal.reduce((acc, current, index) => {
        return current ? acc.concat(index) : acc
      }, [])

    if (e.target.checked) {
      allChecked.push(index)
    } else {
      const currentIndex = allChecked.indexOf(index)
      if (currentIndex > -1) {
        allChecked.splice(currentIndex, 1)
      }
    }
    searchDeals({ plateFinal: allChecked.join("|") })
  }

  const onSubmit = filterForm.handleSubmit((data) => {
    setIsLoadingPlaceholder(true)
    const mappedData = mapFormFiltersToSearch(data)
    searchDeals(mappedData)

    if (asModalContent) {
      setIsModalOpened(false)
      setModalContent("")
    }
  })

  return (
    <div className="step__filters">
      <form onSubmit={onSubmit}>
        <div className="step__filters-title-group">
          <Text as="p" size="lg" weight={700}>
            {asModalContent ? "Filtrar por:" : "Filtros"}
          </Text>
          <If
            condition={!asModalContent && hasUsedFilter()}
            renderIf={
              <Link href={""} onClick={handleOnClearFilters}>
                Limpar filtros
              </Link>
            }
          />
        </div>
        <br />
        <Text as="p" size="md" className="--light">
          Km máxima:
        </Text>
        <Input.Text
          name="kilometers"
          label="Km"
          className="deal-input"
          required={false}
          type="tel"
          validators={{
            rules: {
              validate: {
                isNotANumber: (value) => !isNaN(value),
              },
            },
            messages: {
              isNotANumber: "Digite apenas números",
            },
          }}
          onChange={(e) => {
            const { withDot, withoutDot } = formatKmInputValue(e?.target?.value)
            filterForm.setValue("kilometers", withDot)
            if (!asModalContent) {
              setIsLoadingPlaceholder(true)
              searchDeals({ maxKm: withoutDot })
            }
          }}
          control={filterForm.control}
          disabled={isNewVehicle}
        />
        <Text as="p" size="sm" className="step__filters-disclaimer">
          Máximo até 900.000 km
        </Text>
        <InputRange
          step={5000}
          maxValue={900000}
          minValue={0}
          value={kmValue}
          onChange={(value) => {
            const { withDot } = formatKmInputValue(value)
            filterForm.setValue("kilometers", withDot)
          }}
          onChangeComplete={(value) => {
            if (!asModalContent) {
              setIsLoadingPlaceholder(true)
              onKmRange(value)
            }
          }}
          disabled={isNewVehicle}
        />
        <br />
        <hr className="divider" />
        <br />
        <Text as="p" size="md" className="--light">
          Final da placa
        </Text>
        <div className="step__radio-group">
          {/* TODO: Transferir isso para um arquivo de data */}
          {[
            {
              value: "0",
              text: "0",
            },
            {
              value: "1",
              text: "1",
            },
            {
              value: "2",
              text: "2",
            },
            {
              value: "3",
              text: "3",
            },
            {
              value: "4",
              text: "4",
            },
            {
              value: "5",
              text: "5",
            },
            {
              value: "6",
              text: "6",
            },
            {
              value: "7",
              text: "7",
            },
            {
              value: "8",
              text: "8",
            },
            {
              value: "9",
              text: "9",
            },
          ].map(({ text }, index) => (
            <Checkbox
              key={text}
              name={`plateFinal[${index}]`}
              label={text}
              onChange={(e) => {
                if (!asModalContent) {
                  setIsLoadingPlaceholder(true)
                  setFinalPlate(e, index)
                }
              }}
              control={filterForm?.control}
            />
          ))}
        </div>
        <br />
        <hr className="divider" />
        <br />
        <Text as="p" size="md" className="--light">
          Carro
        </Text>
        <br />
        <div className="step__is-new-vehicle-row">
          <Checkbox
            name="isNewVehicle"
            label="0Km"
            onChange={(e) => {
              const { value } = e.target
              const isNew = value !== "true"
              filterForm.setValue("isNewVehicle", value)

              if (isNew) {
                filterForm.setValue("kilometers", "")
              }

              if (!asModalContent) {
                setIsLoadingPlaceholder(true)
                searchDeals({
                  newVehicles: isNew,
                  ...(isNew ? { maxKm: "" } : {}),
                })
              }
            }}
            control={filterForm.control}
          />

          {/* <Checkbox
            name="usedVehicles"
            label="Usados"
            onChange={(e) => {
              const { value } = e.target
              const isUsed = value !== "true"
              filterForm.setValue("usedVehicles", value)

              if (!asModalContent) {
                setIsLoadingPlaceholder(true)
                searchDeals({
                  usedVehicles: isUsed,
                })
              }
            }}
            control={filterForm.control}
          /> */}
        </div>
        <br />
        <hr className="divider" />
        <br />
        <Text as="p" size="md" className="--light">
          Localização
        </Text>
        <div className="step__city-select-container">
          <Select
            name="city"
            minSearchCharacters={2}
            isSearchingMessage="buscando..."
            label="Cidade, estado"
            required={false}
            fetchOptions={onSearchCities}
            onSelectItem={({ value, state }) => {
              filterForm.setValue("city", value, {
                shouldValidate: true,
              })

              filterForm.setValue("state", state, {
                shouldValidate: true,
              })

              if (!asModalContent) {
                setIsLoadingPlaceholder(true)
                searchDeals({ city: value, state: state })
              }
            }}
            getInputValue={(fieldValue) => {
              const state = filterForm.getValues("state")

              if (!state) {
                return fieldValue
              }

              return `${fieldValue}, ${state}`
            }}
            onBlur={(e) => {
              if (!e?.target?.value && !asModalContent) {
                setIsLoadingPlaceholder(true)
                searchDeals({ city: "", state: "" })
              }
            }}
            control={filterForm.control}
            formProps={filterForm}
          />
          {/* <Select
            name="cityRange"
            label="Ver anúncios até"
            required={false}
            options={[
              { label: "25 Km", value: 25 },
              { label: "50 Km", value: 50 },
              { label: "100 Km", value: 100 },
            ]}
            onSelectItem={({ value }) => {
              filterForm.setValue("cityRange", value, {
                shouldValidate: true,
              })
            }}
            control={filterForm.control}
            formProps={filterForm}
          /> */}
        </div>
        <br />
        <hr className="divider" />
        <br />
        <Text as="p" size="md" className="--light">
          Preço
        </Text>
        <div className="step__prices-container">
          <Input.Currency
            name="initialPrice"
            label="De"
            autoComplete="no"
            value={initialPrice || ""}
            required={false}
            control={filterForm.control}
            onChange={(value) => {
              if (!asModalContent) {
                setIsLoadingPlaceholder(true)
                searchDeals({
                  initialPrice: value,
                  finalPrice: finalPrice || "",
                })
              }
            }}
          />
          <Input.Currency
            name="finalPrice"
            label="Até"
            autoComplete="no"
            value={finalPrice || ""}
            required={false}
            onChange={(value) => {
              if (!asModalContent) {
                setIsLoadingPlaceholder(true)
                searchDeals({
                  initialPrice: initialPrice || "",
                  finalPrice: value,
                })
              }
            }}
            control={filterForm.control}
          />
        </div>
        <br />
        <If
          condition={asModalContent}
          renderIf={
            <>
              <br />
              <Button
                variant="secondary"
                fluid
                type="button"
                onClick={onCloseModal}
              >
                Cancelar
              </Button>
              <Button type="submit" fluid>
                Aplicar
              </Button>
              <br />
              <br />
              <br />
            </>
          }
        />
        <br />
        <br />
      </form>
    </div>
  )
}

export default FilterTemplate
