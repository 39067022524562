import { useState, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import analytics from "@mobi/libraries/analytics"

// Services
import services from "services"
import { updateToken } from "services/auth"

// Utils
import getQueryStringFromObject from "utils/getQueryStringFromObject"
import getTrackingData from "utils/getTrackingData"
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"
import { fillField, fillForm } from "utils/forms"
import { getUrlParamAll, getUUIDFromUrl, resetFocus } from "utils/browser"

// Hooks
import useRecaptchaToken from "hooks/useRecaptchaToken"
import useMake from "hooks/useMake"

// Contexts
import { Context } from "contexts/simulation"
import { useStep } from "@mobi/libraries/step"

const usePersonalData = () => {
  const { next } = useStep()
  const { setSimulation, simulation } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues] = useState({
    fullName: "cesar",
    email: "",
    documentNumber: "",
    phone: "",
  })

  const { token } = useRecaptchaToken()
  const { onFormContinued } = useMake()

  const uuid = getUUIDFromUrl()
  const stepName = "personal-data"

  const formProps = useForm({
    mode: "onChange",
    // defaultValues: initialValues,
  })

  useEffect(() => {
    const getSimulation = async () => {
      if (uuid) {
        setIsLoading(true)

        const response = await services.personalData.getSimulation()

        if (response) {
          fillForm({
            simulation: {
              ...initialValues,
              ...response?.lead,
            },
            formProps,
          })

          setIsLoading(false)
          return response
        }

        setIsLoading(false)
      } else {
        const documentNumberSaved = getFromSessionStorage({ name: "document" })
        formProps.setValue("documentNumber", documentNumberSaved)

        fillField({
          fieldName: "documentNumber",
          value: documentNumberSaved,
          formProps,
        })
      }
    }

    getSimulation()

    setSimulation({
      ...simulation,
      currentFormStep: "personal-data",
    })
    analytics.track("pageLoad", { pageName: "DadosPessoais" })

    resetFocus()
  }, [])

  const updateLocalStep = ({ payload, updatedData }) => {
    const { auth, nextFormStep } = updatedData
    updateToken(auth)
    saveOnSessionStorage({ name: "phone", value: payload.phone })
    setSimulation({
      ...updatedData,
      currentFormStep: nextFormStep,
    })
    onNext({ data: updatedData, next })

    analytics.track("buttonClickGa", { eventName: "Avancar" })
    prepareToMakeTrack()
  }

  const prepareToMakeTrack = () => {
    const fieldsInfo = Object.entries(formProps.getValues()).map(
      (entry) => entry
    )
    const stepContext = {
      stepName: "personalData",
      stepNumber: 2,
    }
    onFormContinued(fieldsInfo, stepContext)
  }

  const onSubmit = async (payload) => {
    setIsLoading(true)
    if (uuid) {
      const updatedSimulation = await services.personalData
        .update(payload, { uuid })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
      if (updatedSimulation?.status === 200) {
        updateLocalStep({ payload, updatedData: updatedSimulation.data })
        return updatedSimulation.data
      }
    } else {
      const trackingData = await getTrackingData().catch((err) =>
        console.log(err)
      )

      const createdSimulation = await services.personalData
        .createSimulation(payload, trackingData, token)
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })

      if (createdSimulation?.status === 200) {
        updateLocalStep({ payload, updatedData: createdSimulation?.data })
      }
    }
  }

  return {
    isLoading,
    formProps,
    stepName,
    onSubmit,
  }
}

const onNext = ({ data, next }) => {
  const { leadUUID } = data
  const currentParams = getUrlParamAll()
  const query = getQueryStringFromObject({
    ...currentParams,
    leadUUID,
  })

  next(data?.nextFormStep)
  navigate(`${query}`)
}

export default usePersonalData
