import classnames from "classnames"

// Components
import If from "@mobi/components/If"
import Sender from "components/Sender"
import { Pagination } from "@vehicles/components"
import { Text, Stepper, Radio, Input, Select, Checkbox, Loader } from "@mobi/ds"
import analytics from "@mobi/libraries/analytics"
import Img from "components/Img"

// Hooks
import useSeller from "./useSeller"

// Mocks
import states from "@vehicles/data/states"

// Style
import "./index.scss"

const Seller = () => {
  const {
    onSearchSellers,
    onSearchCities,
    onSearchNeighborhoods,
    onPrev,
    onSubmit,
    setCities,
    setNeighborhoods,
    setCurrentPage,
    selectSeller,
    onKeyDown,
    setActiveDescendant,
    brandModelVersion,
    isNewVehicle,
    sellersPagination,
    currentPage,
    cities,
    neighborhoods,
    sellers,
    isLoading,
    formProps,
    activeDescendant,
  } = useSeller()

  const resetSeller = () => {
    formProps.setValue("sellerUUID", "", {
      shouldValidate: true,
    })
    formProps.setValue("displayName", "", {
      shouldValidate: true,
    })
  }

  const getCurrentValue = (field) => formProps.getValues()[field]

  const onNoStoreName = () => {
    formProps.setValue("displayName", "", { shouldValidate: false })
    formProps.setValue("state", "", { shouldValidate: true })
    formProps.setValue("city", "", { shouldValidate: true })
    resetSeller()

    analytics.track("buttonClickGa", { eventName: "NaoEncontreiNaoSei" })
  }

  const { control, handleSubmit, watch } = formProps

  const send = (values) => {
    onSubmit(values)
  }

  const getUpdatedValue = (fieldName) => watch(fieldName)

  const isValid = getUpdatedValue("displayName")
    ? getUpdatedValue("displayName") && getUpdatedValue("sellerUUID")
    : getUpdatedValue("notsure")

  console.log(isValid)

  const onPaginationSelect = (e) => {
    const value = parseInt(e?.target?.value)
    onSearchSellers({
      state: getCurrentValue("state"),
      city: getCurrentValue("city"),
      neighborhood: getCurrentValue("neighborhood"),
      term: getCurrentValue("displayName"),
      limit: 8,
      page: value,
      brandModelVersion,
    })
    setCurrentPage(value)
  }

  const onPaginationPrev = () => {
    const prevPage = currentPage === 0 ? 0 : currentPage - 1
    onSearchSellers({
      state: getCurrentValue("state"),
      city: getCurrentValue("city"),
      neighborhood: getCurrentValue("neighborhood"),
      term: getCurrentValue("displayName"),
      limit: 8,
      page: prevPage,
      brandModelVersion,
    })
    setCurrentPage(prevPage)
  }

  const onPaginationNext = () => {
    const nextPage =
      currentPage + 1 === sellersPagination?.pages
        ? currentPage
        : currentPage + 1
    onSearchSellers({
      state: getCurrentValue("state"),
      city: getCurrentValue("city"),
      neighborhood: getCurrentValue("neighborhood"),
      term: getCurrentValue("displayName"),
      limit: 8,
      page: nextPage,
      brandModelVersion,
    })
    setCurrentPage(nextPage)
  }

  const onPageChange = () => {
    const firstSellerItem = document.querySelector(
      "li.form__seller-list__item:nth-child(1)"
    )
    firstSellerItem && firstSellerItem.focus()
  }

  return (
    <div className="form">
      <If condition={isLoading} renderIf={<Loader />} />
      <div className="form__container">
        <div className="form__aside-image">
          <Img
            className="image"
            style={{ position: "absolute" }}
            file="flow-image"
            alt=""
            placeholder="blurred"
            role="presentation"
          />
        </div>
        <div className="form__aside-content">
          <div className="form__wrapper">
            <Stepper title="escolha de loja" index={3} max={4} />
            <Text as="h1" size="xxl">
              <strong>escolha a loja ou concessionária</strong>
            </Text>
            <Text size="md">Onde gostaria de comprar seu carro?</Text>

            <form
              onSubmit={handleSubmit(send)}
              className="form__form-default"
              noValidate
            >
              <Select
                name="state"
                label="Estado"
                minSearch={2}
                required={false}
                options={states}
                onSelectItem={({ value }) => {
                  onSearchCities(value, { limit: 250 }).then((res) =>
                    setCities(res)
                  )
                  formProps.setValue("state", value, {
                    shouldValidate: true,
                  })
                }}
                onFocus={() => {
                  formProps?.reset({
                    notsure: false,
                  })
                }}
                control={control}
                formProps={formProps}
              />
              <div
                className={classnames({
                  "--hidden": !getUpdatedValue("state"),
                })}
              >
                <Select
                  name="city"
                  label="Cidade"
                  required={false}
                  minSearch={2}
                  options={cities}
                  fetchOptions={({ search }) =>
                    onSearchCities(getUpdatedValue("state"), {
                      city: search,
                      limit: 250,
                    })
                  }
                  onSelectItem={({ value }) => {
                    onSearchNeighborhoods(getUpdatedValue("state"), value).then(
                      (res) => setNeighborhoods(res)
                    )

                    onSearchSellers({
                      state: getUpdatedValue("state"),
                      city: value,
                      limit: 8,
                      page: 0,
                      brandModelVersion,
                      isNewVehicle,
                    })
                  }}
                  onFocus={() => {
                    formProps.setValue("city", "", { shouldValidate: true })
                    formProps.setValue("neighborhood", "", {
                      shouldValidate: true,
                    })
                    formProps.setValue("displayName", "", {
                      shouldValidate: true,
                    })
                    formProps.setValue("sellerUUID", "", {
                      shouldValidate: true,
                    })
                  }}
                  control={control}
                  formProps={formProps}
                />
              </div>
              <div
                className={classnames({
                  "--hidden": !(
                    getUpdatedValue("state") && getUpdatedValue("city")
                  ),
                })}
              >
                <>
                  <fieldset>
                    <Text as="legend" size="sm">
                      Deseja filtrar por bairro?
                    </Text>
                    <div className="radio-flex">
                      <Radio
                        control={control}
                        name="searchByNeighborhood"
                        id="nao"
                        value="nao"
                        label="não"
                        onChange={({ target }) => {
                          const { value } = target
                          onSearchSellers({
                            state: getUpdatedValue("state"),
                            city: getUpdatedValue("city"),
                            limit: 8,
                            page: 0,
                            brandModelVersion,
                            isNewVehicle,
                          })
                          formProps.setValue("searchByNeighborhood", value, {
                            shouldValidate: true,
                          })
                          formProps.setValue("neighborhood", "", {
                            shouldValidate: true,
                          })
                          formProps.setValue("displayName", "", {
                            shouldValidate: true,
                          })
                          resetSeller()
                        }}
                      />
                      <Radio
                        name="searchByNeighborhood"
                        id="sim"
                        value="sim"
                        label="sim"
                        control={control}
                        onChange={({ target }) => {
                          const { value } = target
                          onSearchSellers({
                            state: getUpdatedValue("state"),
                            city: getUpdatedValue("city"),
                            limit: 8,
                            page: 0,
                            brandModelVersion,
                            isNewVehicle,
                          })
                          formProps.setValue("searchByNeighborhood", value, {
                            shouldValidate: true,
                          })
                          formProps.setValue("neighborhood", "", {
                            shouldValidate: true,
                          })
                          formProps.setValue("displayName", "", {
                            shouldValidate: true,
                          })
                          resetSeller()
                        }}
                      />
                    </div>
                  </fieldset>
                </>
              </div>

              <div
                className={classnames({
                  "--hidden": getUpdatedValue("searchByNeighborhood") !== "sim",
                })}
              >
                <Select
                  name="neighborhood"
                  label="bairro"
                  minSearch={2}
                  required={false}
                  options={neighborhoods}
                  control={control}
                  formProps={formProps}
                  fetchOptions={({ search }) =>
                    onSearchNeighborhoods(
                      getUpdatedValue("state"),
                      getUpdatedValue("city"),
                      {
                        neighborhood: search,
                        limit: 200,
                      }
                    )
                  }
                  onSelectItem={({ value }) => {
                    onSearchSellers({
                      state: getUpdatedValue("state"),
                      city: getUpdatedValue("city"),
                      neighborhood: value,
                      limit: 8,
                      page: 0,
                      brandModelVersion,
                      isNewVehicle,
                    })

                    resetSeller()
                  }}
                  tabindex={
                    getUpdatedValue("searchByNeighborhood") == "sim"
                      ? "0"
                      : "-1"
                  }
                />
              </div>
              <div
                className={classnames({
                  "--hidden": !(
                    getUpdatedValue("state") && getUpdatedValue("city")
                  ),
                })}
              >
                <Input.Text
                  name="displayName"
                  label="filtrar por nome da loja (opcional)"
                  icon="search"
                  required={false}
                  control={control}
                  onFocus={() => {
                    resetSeller()
                  }}
                  onChange={({ target }) => {
                    const { value } = target

                    if (value.length >= 2) {
                      onSearchSellers({
                        state: getUpdatedValue("state"),
                        city: getUpdatedValue("city"),
                        neighborhood: getUpdatedValue("neighborhood"),
                        page: 0,
                        term: value,
                        limit: 8,
                        brandModelVersion,
                        isNewVehicle,
                      })
                    } else {
                      onSearchSellers({
                        state: getUpdatedValue("state"),
                        city: getUpdatedValue("city"),
                        neighborhood: getUpdatedValue("neighborhood"),
                        page: 0,
                        limit: 8,
                        brandModelVersion,
                        isNewVehicle,
                      })
                    }
                  }}
                  {...(getUpdatedValue("displayName")?.length > 0 &&
                    sellers.length > 0 && {
                      "aria-activedescendant": "resultitem-0",
                    })}
                  aria-invalid={
                    (getUpdatedValue("city") && !sellers) || "false"
                  }
                  tabindex="0"
                />
              </div>

              <If
                condition={getUpdatedValue("city") && !sellers}
                renderIf={
                  <Text as="h5" size="md">
                    <strong>Nenhuma loja encontrada com esse nome</strong>
                  </Text>
                }
              />
              <If
                condition={
                  getUpdatedValue("city") && sellers && sellers?.length > 0
                }
                renderIf={
                  // TODO: Separar a lista em um componente
                  <div className="form__seller-list">
                    <Text as="p" size="sm">
                      Selecione uma das lojas:
                    </Text>
                    <br />
                    <Text as="p" size="xl" aria-hidden="true">
                      <strong>{sellersPagination?.total} lojas</strong>
                    </Text>
                    <br />
                    <div className="seller__feedback-msg" role="status">
                      <If
                        condition={sellers && sellers.length}
                        renderIf={`${sellersPagination?.total} lojas encontradas. Selecione uma loja da lista abaixo. Para mudar de página use o seletor de páginas depois da lista de lojas abaixo.`}
                      />
                    </div>
                    <ul
                      id="seller-list__items"
                      className="form__seller-list__items"
                      aria-label="lista de lojas"
                      role="listbox"
                      aria-activedescendant={activeDescendant}
                    >
                      {sellers?.map((item, index) => {
                        const { displayName, neighborhood, city, sellerUUID } =
                          item
                        const isSelected =
                          getUpdatedValue("sellerUUID") === sellerUUID

                        {
                          /* TODO: separar isso aqui em uma função a parte nesse mesmo arquivo */
                        }
                        return (
                          <li
                            key={sellerUUID}
                            className={classnames("form__seller-list__item", {
                              "form__seller-list__item--selected": isSelected,
                            })}
                            id={`resultitem-${index}`}
                            role="option"
                            aria-selected={isSelected}
                            tabIndex="0"
                            onClick={() => {
                              selectSeller(sellerUUID, displayName)
                              setActiveDescendant(`resultitem-${index}`)
                            }}
                            onKeyDown={(e) => {
                              onKeyDown({ ...e, selected: item })
                              setActiveDescendant(`resultitem-${index}`)
                            }}
                          >
                            <p className="form__seller-list__name">
                              {displayName}
                            </p>
                            <p
                              className="form__seller-list__address"
                              {...(isSelected
                                ? {
                                    "aria-label": `${neighborhood} - ${city}, selecionado`,
                                  }
                                : "")}
                            >
                              {neighborhood} - {city}
                            </p>
                          </li>
                        )
                      })}
                    </ul>
                    <If
                      condition={sellersPagination?.total > 8}
                      renderIf={
                        <Pagination
                          pages={sellersPagination.pages}
                          currentPage={currentPage}
                          onSelectPage={onPaginationSelect}
                          onPrev={onPaginationPrev}
                          onNext={onPaginationNext}
                          onPageChange={onPageChange}
                        />
                      }
                    />
                  </div>
                }
              />

              <If
                condition={
                  getUpdatedValue("sellerUUID") || getUpdatedValue("notsure")
                }
                renderIf={
                  <If
                    condition={getUpdatedValue("sellerUUID")}
                    renderIf={
                      <Text as="p" size="xxs">
                        Para facilitar o processo, ao prosseguir, avisaremos a
                        loja ou concessionária selecionada que você tem
                        interesse nesse veículo.
                      </Text>
                    }
                    renderElse={
                      <Text as="p" size="xxs">
                        Para te ajudar no processo, ao prosseguir, avisaremos um
                        especialista de financiamento que você tem interesse
                        nesse veículo.
                      </Text>
                    }
                  />
                }
              />

              <Sender
                isNextDisabled={!isValid}
                hasPrev={true}
                isLoading={isLoading}
                onPrev={onPrev}
                onNext={handleSubmit(onSubmit)}
                nextLabel="continuar simulação"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Seller
