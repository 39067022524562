import { useState, useEffect, useContext, useCallback } from "react"
import { useForm } from "react-hook-form"

// Services
import services from "services"
import entities from "@entities"

// Utils
import { fillForm } from "utils/forms"
import { getUUIDFromUrl, resetFocus } from "utils/browser"

// Contexts
import { Context } from "contexts/simulation"

//Hooks
import useMake from "hooks/useMake"
import { getFromSessionStorage, saveOnSessionStorage } from "utils/storage"

const useVehicleDealPropertiesForm = ({
  setIsLoading,
  asModalContent,
  updateLocalStep,
}) => {
  const { setSimulation, simulation } = useContext(Context)
  const { onFormContinued } = useMake()
  const [vehiclesYears, setVehiclesYears] = useState([])
  const [cities, setCities] = useState([])
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [isModelsLoading, setIsModelsLoading] = useState(false)

  const formProps = useForm({
    mode: "onChange",
    defaultValues: {
      initialPrice: null,
      finalPrice: null,
      initialYear: null,
      finalYear: null,
      brand: null,
      model: null,
      purchasePeriod: "",
      dealCity: "",
      dealState: "",
    },
  })

  const brand = formProps.watch("brand")

  const getModels = useCallback(async (brandId) => {
    if (brandId) {
      const newModels = await services.vehicles.getModels({
        brand: brandId,
      })

      setModels(newModels)

      return newModels
    }

    return []
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setIsLoading(true)
    resetFocus()

    const getSimulation = async () => {
      const response = await services.vehicles.getSimulation().catch(() => {
        setIsLoading(false)
      })
      const responseVehicles = response?.vehicleDealProperties

      await getYears({ setVehiclesYears }).catch(() => {
        setIsLoading(false)
      })

      const vehiclesBrands = await services.vehicles.getBrands()
      setBrands(vehiclesBrands)
      const brandValue = vehiclesBrands.find(
        (item) => item.value === responseVehicles.brandId
      )

      let modelValue = null

      if (responseVehicles.brandId) {
        const vehiclesModels = await getModels(responseVehicles.brandId)
        modelValue = vehiclesModels.find(
          (item) => item.value === responseVehicles.modelId
        )
      }

      const initialPrice = getFromSessionStorage({ name: "initialPrice" })
      const finalPrice = getFromSessionStorage({ name: "finalPrice" })
      const initialYear = getFromSessionStorage({ name: "initialYear" })
      const finalYear = getFromSessionStorage({ name: "finalYear" })

      if (responseVehicles) {
        fillForm({
          simulation: {
            ...responseVehicles,
            brand: brandValue
              ? { id: brandValue?.value, name: brandValue?.label }
              : null,
            model: modelValue
              ? { id: modelValue?.value, name: modelValue?.label }
              : null,
            initialPrice,
            finalPrice,
            initialYear: initialYear ? initialYear : null,
            finalYear: finalYear ? finalYear : null,
          },
          formProps,
        })
        setIsLoading(false)

        setSimulation(
          asModalContent
            ? {
                ...simulation,
                ...response,
                ...{
                  vehicleDealProperties: {
                    ...(simulation.vehicleDealProperties || {}),
                    ...(response.vehicleDealProperties || {}),
                    initialPrice: initialPrice ? initialPrice : undefined,
                    finalPrice: finalPrice ? finalPrice : undefined,
                  },
                },
                currentFormStep: simulation.currentFormStep,
                nextFormStep: simulation.nextFormStep,
                previousFormStep: simulation.previousFormStep,
              }
            : {
                ...response,
                ...{
                  vehicleDealProperties: {
                    ...(response.vehicleDealProperties || {}),
                    initialPrice: initialPrice ? initialPrice : undefined,
                    finalPrice: finalPrice ? finalPrice : undefined,
                  },
                },
              }
        )
      }

      setIsLoading(false)
      return responseVehicles
    }

    getSimulation()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { id: brandId } = brand || {}
      if (brandId) {
        setIsModelsLoading(true)

        await getModels(brandId)

        setIsModelsLoading(false)
      }
    })()
  }, [brand, getModels])

  const prepareToMakeTrack = () => {
    const formValues = entities.VehicleDealProperties(formProps.getValues())
    const fieldsInfo = Object.entries(formValues).map((entry) => entry)
    const stepContext = {
      stepName: "vehicleDealProperties",
      stepNumber: 2,
    }
    onFormContinued(fieldsInfo, stepContext)
  }

  const onSubmit = async (payload) => {
    setIsLoading(true)

    saveOnSessionStorage({
      name: "initialPrice",
      value: payload.initialPrice,
    })
    saveOnSessionStorage({
      name: "finalPrice",
      value: payload.finalPrice,
    })
    saveOnSessionStorage({
      name: "initialYear",
      value: payload.initialYear,
    })
    saveOnSessionStorage({
      name: "finalYear",
      value: payload.finalYear,
    })

    const updatedSimulation = await services.vehicles
      .update(payload, { sourceUrl: window?.location?.href })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })

    if (updatedSimulation?.status === 200) {
      updateLocalStep(updatedSimulation)
      prepareToMakeTrack()
    }
  }

  const onSearchCities = async (city, filters) => {
    const search = { city: city.search }
    const response = await services.places.getCities(search, filters)
    if (response) return response
  }

  return {
    setIsLoading,
    onSearchCities,
    setCities,
    cities,
    brands,
    models,
    formProps,
    simulation,
    setSimulation,
    vehiclesYears,
    onSubmit,
    brand,
    isModelsLoading,
  }
}

const getYears = async ({ setVehiclesYears }) => {
  const uuid = getUUIDFromUrl()
  if (!uuid) return Promise.reject()
  const response = await services.vehicles.getYears({ [uuid.name]: uuid.value })
  const years = response
  const filteredYears = years.filter(
    (item, index, acc) => acc.findIndex((t) => t.value === item.value) === index
  )
  setVehiclesYears(filteredYears)
  return years
}

export default useVehicleDealPropertiesForm
