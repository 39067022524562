import { useCallback, useContext, useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import * as debounce from "lodash.debounce"

// Contexts
import { Context } from "contexts/simulation"
import { useStep } from "@mobi/libraries/step"

// Services
import services from "services"

//Hooks
import useMake from "hooks/useMake"
import useLatestPropsRef from "hooks/useLatestPropsRef"

//Utils
import { getFromSessionStorage, saveOnSessionStorage } from "utils/storage"

const filterFormDefaultValues = {
  year: "",
  isNewVehicle: false,
  kilometers: "",
  initialPrice: "",
  finalPrice: "",
  city: "",
  plateFinal: [...Array(10).keys()].map(() => false), // 10 is the number of possibles finals of a plate
}

const useDeal = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPlaceholder, setIsLoadingPlaceholder] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastFiltersUsed, setLastFiltersUsed] = useState({
    year: "",
    versionId: "",
    newVehicles: "",
    state: "",
    city: "",
    limit: 9,
    page: "",
    maxKm: "",
    plateFinal: "",
    initialPrice: "",
    finalPrice: "",
    sort: "featured",
    isZeroKm: false,
  })
  const [selectedVehicle, selectVehicle] = useState({})
  const [stepData, setStepData] = useState({
    limit: 10,
    pages: 1,
    total: 1,
    count: 1,
    deals: [],
  })

  const { next, prev } = useStep()

  const { setSimulation, simulation } = useContext(Context)
  const latestSimulationRef = useLatestPropsRef(simulation)

  const { onProductListViewed, onProductListFiltered, onDealFormContinued } =
    useMake()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const filterForm = useForm({
    mode: "onChange",
    defaultValues: filterFormDefaultValues,
  })

  const sortForm = useForm({
    mode: "onChange",
    defaultValues: {
      sort: "destaques",
    },
  })

  const setLastFiltersForm = () => {
    const { city, finalPrice, initialPrice, maxKm, newVehicles, plateFinal } =
      lastFiltersUsed

    const { withDot: kilometersWithDot } = formatKmInputValue(maxKm)
    const allPlatesFinal = [...Array(10).keys()] // 10 is the number of possibles finals of a plate
      .map((_, index) => !!plateFinal.includes(index))

    filterForm.reset(
      {
        city,
        finalPrice,
        initialPrice,
        kilometers: kilometersWithDot,
        isNewVehicle: newVehicles,
        plateFinal: allPlatesFinal,
      },
      {
        keepDefaultValues: true,
      }
    )
  }

  const getDeal = useCallback(async () => {
    const response = await services.deal.getSimulation().catch((err) => {
      setIsLoading(false)
      console.log(err)
    })

    const dealCity = response.dealCity || ""
    const dealState = response.dealState || ""

    if (response) {
      filterForm.setValue("city", dealCity)
      filterForm.setValue("isNewVehicle", !!response.isNewVehicle)

      const initialPrice = getFromSessionStorage({ name: "initialPrice" })
      const finalPrice = getFromSessionStorage({ name: "finalPrice" })
      const initialYear = getFromSessionStorage({ name: "initialYear" })
      const finalYear = getFromSessionStorage({ name: "finalYear" })

      setSimulation({
        ...simulation,
        ...response,
        initialYear,
        finalYear,
        initialPrice,
        finalPrice,
      })

      filterForm.setValue("initialPrice", initialPrice)
      filterForm.setValue("finalPrice", finalPrice)

      searchDeals(
        {
          year: response.vehicleYear,
          initialYear,
          finalYear,
          initialPrice,
          finalPrice,
          brandId: response.brandId,
          modelId: response.modelId,
          city: dealCity,
          state: dealState || "",
        },
        1
      )
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)

    getDeal()
  }, [])

  const onSearchCities = async (city, filters) => {
    const search = { city: city.search }
    const response = await services.places.getCities(search, filters)
    if (response) return response
  }

  const mapFormFiltersToSearch = ({
    city = "",
    state = "",
    finalPrice,
    initialPrice,
    isNewVehicle,
    kilometers,
    plateFinal,
  }) => {
    const { withoutDot: kilometersWithoutDot } = formatKmInputValue(kilometers)
    const checkedPlates = (plateFinal || []).reduce((acc, current, index) => {
      return current ? acc.concat(index) : acc
    }, [])

    return {
      city,
      state,
      finalPrice,
      initialPrice,
      maxKm: kilometersWithoutDot,
      newVehicles: isNewVehicle,
      plateFinal: checkedPlates.join("|"),
    }
  }

  const handleOnClearFilters = (e, search = true) => {
    e.preventDefault()
    filterForm.reset()

    if (search) {
      searchDeals({
        year: simulation.vehicleDealProperties.vehicleYear,
        city: "",
        state: "",
        finalPrice: "",
        initialPrice: "",
        maxKm: "",
        newVehicles: false,
        plateFinal: "",
      })
    }
  }

  const handleOnClearFilter = (e, formName, searchName) => {
    e.preventDefault()

    filterForm.setValue(formName, filterFormDefaultValues[formName])

    const mappedData = mapFormFiltersToSearch({
      [formName]: filterFormDefaultValues[formName],
    })
    searchDeals({ [searchName]: mappedData[searchName] })
  }

  const hasUsedFilter = () => {
    const { city, finalPrice, initialPrice, maxKm, newVehicles, plateFinal } =
      lastFiltersUsed
    if (
      city ||
      finalPrice ||
      initialPrice ||
      maxKm ||
      newVehicles ||
      plateFinal
    )
      return true

    return false
  }

  const changeDealCity = useCallback(
    async (city, state = "") => {
      if (latestSimulationRef.current.dealCity !== city) {
        const updatedSimulation = await services.vehicles
          .update(
            {
              ...latestSimulationRef.current,
              brand: {
                id: latestSimulationRef.current.brandId,
                name: latestSimulationRef.current.brandName,
              },
              model: {
                id: latestSimulationRef.current.modelId,
                name: latestSimulationRef.current.modelName,
              },
              dealCity: city,
              dealState: state,
            },
            { sourceUrl: window?.location?.href }
          )
          .catch((err) => {
            console.log(err)
            setIsLoading(false)
          })

        if (updatedSimulation?.status === 200) {
          setSimulation({
            ...latestSimulationRef.current,
            dealCity: city,
            dealState: state,
          })
        }
      }
    },
    [latestSimulationRef, setSimulation]
  )

  const searchDeals = useRef(
    debounce(async (filters, page) => {
      const finalCurrentPage = page ? page : currentPage
      if (page) {
        setCurrentPage(finalCurrentPage)
      }

      selectVehicle({})

      let newFiltersUsed = {}
      setLastFiltersUsed((oldFiltersUsed) => {
        newFiltersUsed = {
          ...oldFiltersUsed,
          ...filters,
          page: finalCurrentPage,
        }

        saveOnSessionStorage({
          name: "initialPrice",
          value: newFiltersUsed.initialPrice,
        })
        saveOnSessionStorage({
          name: "finalPrice",
          value: newFiltersUsed.finalPrice,
        })

        changeDealCity(newFiltersUsed.city, newFiltersUsed.state)

        return newFiltersUsed
      })

      const stepData = await services.deal
        .getDeals(newFiltersUsed)
        .catch(() => {
          setIsLoading(false)
          setIsLoadingPlaceholder(false)
        })

      if (stepData?.deals) {
        setStepData(stepData)
        onProductListViewed({
          deals: stepData.deals,
          currentPage: finalCurrentPage,
        })
        const filters = Object.entries(newFiltersUsed).map((entry) => entry)
        onProductListFiltered({
          filtersList: filters,
          deals: stepData.deals,
          currentPage: finalCurrentPage,
        })
      } else {
        setStepData({ ...stepData, deals: [] })
      }
      setIsLoading(false)
      setIsLoadingPlaceholder(false)
    }, 500)
  ).current

  const onPrev = () => {
    const newSimulationOnPrev = {
      ...simulation,
      nextFormStep: simulation.currentFormStep,
      currentFormStep: simulation.previousFormStep,
    }
    setSimulation(newSimulationOnPrev)
    prev(simulation?.previousFormStep)
  }

  const prepareToMakeTrack = () => {
    const fieldsInfo = Object.entries(selectedVehicle).map((value) => value)

    const stepContext = {
      stepName: "deal",
      stepNumber: 4,
    }

    onDealFormContinued(fieldsInfo, stepContext)
  }

  const onNext = async (vehicle) => {
    setIsLoading(true)
    const newVehicle = selectedVehicle.dealId ? selectedVehicle : vehicle
    const updatedSimulation = await services.deal
      .update(newVehicle, { sourceUrl: window?.location?.href })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })

    if (updatedSimulation.status === 200) {
      const data = updatedSimulation?.data
      const nextStep = data?.nextFormStep
      setSimulation({
        ...data,
        currentFormStep: nextStep,
      })
      next(nextStep)
      prepareToMakeTrack()

      return data
    }
  }

  const formatKmInputValue = (value) => {
    if (isNaN(value) || Number(value) === 0) {
      return { withDot: "", withoutDot: "" }
    }

    const getWithoutDot = (num) => num.toString().replaceAll(".", "")
    const getWithDot = (num) =>
      num.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".")
    const withoutDot = getWithoutDot(value)
    const withDot = getWithDot(withoutDot)

    return { withDot, withoutDot }
  }

  const onKmRange = (value) => {
    const { withoutDot } = formatKmInputValue(value)
    searchDeals({ maxKm: withoutDot })
  }

  return {
    onKmRange,
    sortForm,
    filterForm,
    isLoading,
    isLoadingPlaceholder,
    stepData,
    selectedVehicle,
    currentPage,
    lastFiltersUsed,
    selectVehicle,
    searchDeals,
    onPrev,
    onSearchCities,
    onNext,
    setIsLoadingPlaceholder,
    setLastFiltersForm,
    handleOnClearFilters,
    hasUsedFilter,
    mapFormFiltersToSearch,
    handleOnClearFilter,
    formatKmInputValue,
    getDeal,
  }
}

export default useDeal
