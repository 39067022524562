import { StepProvider, Step } from "@mobi/libraries/step"

// Steps
import PersonalData from "pages/Form/steps/PersonalData"
import VehicleDealProperties from "pages/Form/steps/VehicleDealProperties"
import Seller from "pages/Form/steps/Seller"
import VerificationCodeGeneration from "pages/Form/steps/VerificationCodeGeneration"
import Simulation from "pages/Form/steps/Simulation"
import Deal from "pages/Form/steps/Deal"

const steps = {
  "personal-data": {
    component: PersonalData,
    title: "dados Pessoais",
  },
  "vehicle-deal-properties": {
    component: VehicleDealProperties,
    title: "dados do veículo",
  },
  seller: {
    component: Seller,
    title: "escolha a loja ou concessionária",
  },
  deal: {
    component: Deal,
    title: "Escolha de veículo",
  },
  "verification-code-generation": {
    component: VerificationCodeGeneration,
    title: "validação de segurança",
  },
  "verification-code-confirmation": {
    component: VerificationCodeGeneration,
    title: "validação de segurança",
  },
  simulation: { component: Simulation, title: "resultado da simulação" },
  "simulation-update": {
    component: Simulation,
    title: "resultado da simulação",
  },
}

const Forms = ({ current }) => {
  return (
    <StepProvider steps={{ ...steps }} start={current}>
      <Step />
    </StepProvider>
  )
}

export default Forms
