import { useRef, useState } from "react"
import classnames from "classnames"

// Components
import { Footer, Text } from "@mobi/ds"
import If from "@mobi/components/If"
import { Likert, ModalCET } from "@vehicles/components"
import Notification from "pages/Form/components/Notification"
import Feedback from "./components/Feedback"
import Recommender from "components/Recommender"
import NextSteps from "components/NextSteps"
import LoaderScreen from "components/LoaderScreen"
import Marketplace from "components/Marketplace"
import FinancingSettings from "components/FinancingSettings"

// Hooks
import useSimulation from "./useSimulation"

// Style
import "./index.scss"

// Services
import { toInstallmentsList } from "services/mappers"

// Utils
import getVehicleLabels from "utils/getVehicleLabels"
import featureToggle from "utils/featureToogle"
import isMobile from "@mobi/utils/browser/isMobile"
import numberFormatter from "@mobi/utils/formatters/number"
import { currencyToFloat } from "@mobi/utils/formatters/currency"

//Data
import { marketplaceCardItems } from "./dataContent"

const Simulation = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const { recommenderIsActive, staticStepperIsActive } = featureToggle()

  const financingSettingsRef = useRef(null)

  const onModalClose = () => {
    setIsModalOpened(false)
    const btnEffectiveCost = document.querySelector(
      ".price-simulator > .footer > a "
    )
    btnEffectiveCost && btnEffectiveCost.focus()
  }

  const {
    formProps,
    stepData,
    simulationSuccess,
    isLoading,
    isFetchingSimulation,
    onSubmit,
    getSelectedOption,
    setSimulationSuccess,
    requestNewSimulation,
  } = useSimulation()

  const getUpdatedValue = (fieldName) => watch(fieldName)
  const { handleSubmit, watch } = formProps

  const seller = stepData?.seller || {}
  const sellerName =
    `${stepData?.displayName?.charAt(0).toUpperCase()}${stepData?.displayName
      ?.toLowerCase()
      .slice(1)}` || ""

  const installments = toInstallmentsList(stepData?.availableInstallmentOptions)

  const numberOfInstallments = getUpdatedValue("numberOfInstallments")

  const selectedOption = {
    ...getSelectedOption({ installments, numberOfInstallments }),
    numberOfInstallments,
  }

  const isDealer = !(
    stepData?.displayName === "Escale" ||
    stepData?.displayName === "MORAIS & PAZ"
  )

  const vehicleDealProperties = {
    ...seller,
    ...stepData,
    ...selectedOption,
  }

  const totalPayable = stepData?.totalPayable

  const iofValue = getSelectedOption({
    installments,
    numberOfInstallments,
  })?.iofValue

  const { totalAmountFinancedWithoutTax } = stepData

  const totalAmount = numberFormatter({
    value: totalAmountFinancedWithoutTax + currencyToFloat(iofValue),
  })

  const onKeyDownEnter = (keyEvent) => {
    if (keyEvent.key === "Enter") {
      keyEvent.preventDefault()
    }
  }

  const send = (values) => {
    const acceptableDownPayment =
      financingSettingsRef.current.getAcceptableDownPayment(values.downPayment)

    formProps.setValue("downPayment", acceptableDownPayment, {
      shouldValidate: true,
    })

    return onSubmit({
      ...values,
      downPayment: acceptableDownPayment,
    })
  }

  const { name, description } = getVehicleLabels(stepData?.brandModelVersion)

  const financingSettingsProperties = {
    stepData,
    formProps,
    name,
    description,
    isLoading,
    requestNewSimulation,
    isDealer,
    onModalOpen: () => setIsModalOpened(true),
    installments,
    setSimulationSuccess,
  }

  return (
    <>
      <ModalCET
        totalAmount={totalAmount}
        totalPayable={totalPayable}
        vehicleDealProperties={vehicleDealProperties}
        isOpened={isModalOpened}
        onCloseClick={onModalClose}
      />
      <If
        condition={isLoading}
        renderIf={<LoaderScreen />}
        renderElse={
          <>
            <div className="simulation__container" aria-hidden={isModalOpened}>
              <div className="simulation__holder">
                <div className="simulation__wrapper">
                  <Feedback
                    seller={seller}
                    sellerName={sellerName}
                    isDealer={isDealer}
                  />
                </div>
                <If
                  condition={!isMobile}
                  renderIf={
                    <>
                      <If
                        condition={staticStepperIsActive}
                        renderIf={
                          <>
                            <NextSteps isDealer={isDealer} />
                          </>
                        }
                      />
                      <If
                        condition={recommenderIsActive}
                        renderIf={
                          <>
                            <Recommender />{" "}
                          </>
                        }
                      />
                    </>
                  }
                />
              </div>

              <div className="simulation__holder">
                <form
                  onSubmit={handleSubmit(send)}
                  className={classnames("simulation__form-default", {
                    "simulation__form-default--loading": isFetchingSimulation,
                  })}
                  onKeyDown={onKeyDownEnter}
                  noValidate
                >
                  <div className="simulation__content">
                    <Text as="h2" size="xxl" weight="800">
                      faça uma simulação
                    </Text>
                    <Text className="simulation__content-paragraph" size="md">
                      <If
                        condition={isDealer}
                        renderIf={
                          <>
                            <strong>Simule as melhores condições</strong> para
                            conversar com o lojista.
                          </>
                        }
                        renderElse={
                          <>
                            <strong>Simule as melhores condições</strong> para
                            conversar com o especialista.
                          </>
                        }
                      />
                    </Text>
                    <FinancingSettings
                      ref={financingSettingsRef}
                      {...financingSettingsProperties}
                    />
                  </div>
                </form>
                <If
                  condition={isMobile}
                  renderIf={
                    <>
                      <div className="simulation__container">
                        <If
                          condition={staticStepperIsActive}
                          renderIf={
                            <>
                              <NextSteps isDealer={isDealer} />
                            </>
                          }
                        />
                        <If
                          condition={recommenderIsActive}
                          renderIf={<Recommender />}
                        />
                        <Likert />
                      </div>
                    </>
                  }
                />
              </div>
            </div>
            <div className="simulation__container">
              <If condition={!isMobile} renderIf={<Likert />} />
            </div>
            <Marketplace cardItems={marketplaceCardItems} />

            <Footer
              ariaHidden={isModalOpened}
              links={{
                urlHelpPage:
                  "https://www.itau.com.br/atendimento-itau/para-voce",
              }}
            />
          </>
        }
      />
    </>
  )
}

export default Simulation
