// Components
import If from "@mobi/components/If"
import Loader from "components/Loader"
import Panel from "pages/Form/components/Panel"
import { Input, Stepper, Text, Link } from "@mobi/ds"
import Sender from "components/Sender"

// Hooks
import usePersonalData from "./usePersonalData"

// Styles
import "./index.scss"

const PersonalData = () => {
  const { onSubmit, isLoading, formProps } = usePersonalData()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = formProps

  return (
    <div className="form">
      <If condition={isLoading} renderIf={<Loader />} />
      <div className="form__container">
        <div className="form__aside-image">
          <Panel />
        </div>
        <div className="form__aside-content">
          <div className="form__wrapper">
            <Stepper title="dados pessoais" index={1} max={4} />
            <form className="form__form-default">
              <Input.Cpf label="cpf" name="documentNumber" control={control} />
              <Input.Name
                label="nome completo"
                name="fullName"
                control={control}
              />
              <Input.Email
                label="e-mail"
                name="email"
                allowSuggestions={false}
                control={control}
              />
              <Input.Cellphone
                label="celular"
                description="ex. (11) 91111-1111"
                name="phone"
                control={control}
              />
              <br aria-hidden="true" />
              <Text size="sm">
                Ao continuar, autorizo os gestores de banco de dados do cadastro
                positivo a disponibilizarem ao Itaú Unibanco S.A o meu histórico
                de crédito. Estou ciente dos{" "}
                <Link
                  href="https://www.itau.com.br/seguranca/termo-autorizacao/"
                  target="_blank"
                  rel="noreferrer"
                  size="sm"
                >
                  termos de autorização
                </Link>{" "}
                e que poderei efetuar o{" "}
                <Link
                  href="https://www.itau.com.br/cadastro-positivo/"
                  target="_blank"
                  rel="noreferrer"
                  size="sm"
                >
                  cancelamento
                </Link>{" "}
                a qualquer momento.
              </Text>
              <Text size="sm">
                Autorizo também a consulta ao{" "}
                <Link
                  href="https://www.itau.com.br/seguranca/sistema-informacao-credito/"
                  target="_blank"
                  rel="noreferrer"
                  size="sm"
                >
                  sistema de informações de crédito
                </Link>{" "}
                (SCR) do Banco Central, estou ciente que meus dados serão
                registrados no SCR e de acordo com a{" "}
                <Link
                  href="https://www.itau.com.br/privacidade"
                  target="_blank"
                  rel="noreferrer"
                  size="sm"
                >
                  política de privacidade
                  <span>.</span>
                </Link>
              </Text>
              <Sender
                isNextDisabled={!isValid}
                hasPrev={false}
                nextLabel="avançar"
                onNext={handleSubmit(onSubmit)}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalData
