//Utils
import isMobile from "@mobi/utils/browser/isMobile"
import phoneMask from "@mobi/utils/formatters/phone"

//Components
import { Button, Text } from "@mobi/ds"
import If from "@mobi/components/If"
import { Icon } from "@vehicles/components"

//Hooks
import useMake from "hooks/useMake"

export default function Contacts({ phone, whatsapp, sellerName }) {
  const { onElementClicked } = useMake()

  const prepareToMakeTrack = (name, text) => {
    const webElement = {
      elementType: "button",
      location: "simulation",
      name: name,
      text: text,
    }

    onElementClicked(webElement)
  }

  const isPhoneEqualWhatsapp = phone === whatsapp
  const isPhoneDifferentWhatsapp = !isPhoneEqualWhatsapp

  const formatPhone = (phone) => {
    return phone ? phoneMask(phone) : null
  }

  return (
    <>
      <div className="simulation__contacts">
        <If
          condition={phone}
          renderIf={
            <div className="simulation__contacts__number">
              <Text className="phonenumber" as="p" size="xl" weight={800}>
                {formatPhone(phone)}
              </Text>
              <If
                condition={phone && whatsapp && isPhoneDifferentWhatsapp}
                renderIf={
                  <Text className="phonenumber" as="p" size="xl">
                    {" "}
                    ou
                  </Text>
                }
              />
            </div>
          }
        />
        <If
          condition={whatsapp && isPhoneDifferentWhatsapp}
          renderIf={
            <>
              <div className="simulation__contacts__number">
                <Text className="phonenumber" as="p" size="xl" weight={800}>
                  {formatPhone(whatsapp)}
                </Text>
              </div>
            </>
          }
        />
        <If
          condition={isMobile && phone}
          renderIf={
            <>
              <br aria-hidden="true" />
              <Button
                tagName="a"
                className="--tertiary"
                leftIcon={<Icon id="icon-telephone-outlined" />}
                href={`tel:${phone}`}
                onClick={() =>
                  prepareToMakeTrack(sellerName, "ligar para o lojista")
                }
              >
                ligar para o lojista
              </Button>
            </>
          }
        />
        <If
          condition={whatsapp}
          renderIf={
            <>
              <br aria-hidden="true" />
              <Button
                tagName="a"
                target="_blank"
                className="button-whatsapp --tertiary"
                leftIcon={<Icon id="icon-whatsapp-outlined" />}
                href={`https://wa.me/55${whatsapp}`}
                onClick={() => prepareToMakeTrack(sellerName, "whatsapp")}
              >
                whatsapp
              </Button>
            </>
          }
        />
      </div>
      <If
        condition={!phone && !whatsapp}
        renderIf={
          <>
            <Text as="p" size="md">
              Agora é com você! Pesquise a melhor forma de entrar em contato com
              a loja{" "}
              {
                <span>
                  <b>{sellerName}</b>
                </span>
              }{" "}
              e consiga uma proposta a partir da sua simulação! Aproveite para
              testar a simulação com outros valores de entrada ;)
            </Text>
          </>
        }
      />
    </>
  )
}
