import { useEffect, useState } from "react"

// Utils
import recaptcha from "utils/recaptcha"

const useRecaptchaToken = () => {
  const [token, setToken] = useState("")

  useEffect(() => {
    function getRecaptchaToken() {
      recaptcha
        .execute()
        .then((newToken) => {
          setToken(newToken)
        })
        .catch(async (err) => {
          if (!err) {
            // When reCAPTCHA script has not been loaded, we need await for it load to try get token again
            await new Promise((res) => setTimeout(res, 200))
            getRecaptchaToken()
          } else {
            console.log(err)
          }
        })
    }

    getRecaptchaToken()
  }, [])

  return { token }
}

export default useRecaptchaToken
