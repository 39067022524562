let flags = {}
try {
  /* globals FEATURE_TOGGLE */
  flags = FEATURE_TOGGLE
} catch {
  console.log("[ Feature toggle ] - empty on this env")
}

const featureToggle = () => {
  return flags || {}
}

export default featureToggle
