import { forwardRef, useImperativeHandle } from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

// Components
import { Input, Select, Text, Button, Link } from "@mobi/ds"
import If from "@mobi/components/If"
import DownPaymentSuggestion from "./DownPaymentSuggestion"
import Loader from "components/Loader"

//Hooks
import useFinancingSettings from "./useFinancingSettings"

// Style
import "./index.scss"

// Utils
import { simulation } from "@vehicles/data"

const { getProbabilityFlag } = simulation

const FinancingSettings = forwardRef(
  (
    {
      onFinish,
      stepData,
      formProps,
      name,
      description,
      isLoading,
      requestNewSimulation,
      isDealer,
      onModalOpen,
      installments,
      setSimulationSuccess,
      trackingInfos,
      ga,
    },
    ref
  ) => {
    const {
      conditions,
      resetCurrencyField,
      setResetCurrencyField,
      downPayment,
      minAcceptableDownPayment,
      maxAcceptable,
      ariaLive,
      setAriaLive,
      debouncedRequestNewSimulation,
      getAcceptableDownPayment,
    } = useFinancingSettings({ stepData, formProps, requestNewSimulation })

    useImperativeHandle(ref, () => ({
      getAcceptableDownPayment,
    }))

    const prepareToTrack = () => {
      if (trackingInfos) {
        ga(trackingInfos.eventName, trackingInfos.params)
      }
    }

    return (
      <div
        className={classnames("price-simulator", {
          [getProbabilityFlag(conditions)]: true,
        })}
      >
        <Loader hideLogo />
        <div className="price-simulator__header">
          <Text className="price-simulator__label" size="md">
            {name}
          </Text>
          <Text className="price-simulator__sublabel" size="sm">
            {description}
          </Text>
          <Text
            as="p"
            className="price-simulator__value"
            size="xl"
            weight="300"
          >
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(stepData?.vehicleValue)}
          </Text>
        </div>

        <div className="price-simulator__content">
          <Input.Currency
            name="downPayment"
            label="Valor da entrada"
            autoComplete="on"
            control={formProps.control}
            shouldResetOn={resetCurrencyField}
            defaultValue={downPayment}
            disabled={isLoading}
            rules={{
              min: minAcceptableDownPayment,
              max: 1200000,
            }}
            validators={{ min: "", max: "", required: "" }}
            onChange={(value) => {
              formProps.setValue("shouldSendToLead", false)

              const downPaymentFromDom = value

              const isLessThanMinDownPaymentFilledOnForm =
                downPaymentFromDom < minAcceptableDownPayment

              const isMoreThanMaxAcceptable = downPaymentFromDom > maxAcceptable

              const isValidValue =
                !isLessThanMinDownPaymentFilledOnForm &&
                !isMoreThanMaxAcceptable

              const downPaymentWasChanged = downPayment !== downPaymentFromDom

              debouncedRequestNewSimulation({
                downPayment: downPaymentFromDom,
                isValidValue,
                downPaymentWasChanged,
              })

              setAriaLive(true)
            }}
            onBlur={async (value) => {
              const isToRequestNewSimulation =
                value !== stepData?.defaultDownPaymentRaw

              if (isToRequestNewSimulation) {
                const acceptableDownPayment =
                  getAcceptableDownPayment(downPayment)

                formProps.setValue("downPayment", acceptableDownPayment)
                await requestNewSimulation(acceptableDownPayment)
              }

              setResetCurrencyField(
                (oldResetCurrencyField) => oldResetCurrencyField + 1
              )
            }}
          />
          <DownPaymentSuggestion
            conditions={conditions}
            vehicleValue={stepData?.vehicleValue}
            downPayment={downPayment}
            maxAcceptable={maxAcceptable}
            minAcceptableDownPayment={minAcceptableDownPayment}
            ariaLive={ariaLive}
          />
          <Select
            name="installments"
            label="Parcelas"
            minSearch={2}
            options={installments}
            disabled={isLoading}
            onChange={async ({ value }) => {
              formProps.setValue("shouldSendToLead", false)

              const numberOfInstallments = value
              formProps.setValue(
                "numberOfInstallments",
                parseInt(numberOfInstallments)
              )

              if (setSimulationSuccess) {
                setSimulationSuccess(false)
              }

              await requestNewSimulation(downPayment, numberOfInstallments)

              setResetCurrencyField(
                (oldResetCurrencyField) => oldResetCurrencyField + 1
              )
            }}
            required={true}
            control={formProps.control}
            formProps={formProps}
            validators={{
              rules: {
                validate: {
                  isValidValue: (conditionValue) =>
                    installments.some((objItem) =>
                      (objItem.label || "").startsWith(
                        (conditionValue || "").split("x")[0]
                      )
                    ),
                },
              },
            }}
          />
        </div>

        <div className="price-simulator__footer">
          <Link
            className="price-simulator__button-cet"
            href=""
            onClick={(e) => {
              e.preventDefault()
              onModalOpen()
            }}
          >
            Detalhes do Custo Efetivo Total (CET)
          </Link>
          <Text size="md">
            <If
              condition={isDealer}
              renderIf={
                <>
                  Operação sujeita à análise de crédito. Confirme os valores da
                  simulação no momento da contratação
                </>
              }
              renderElse={
                <>
                  Operação sujeita à análise de crédito. Confirme os valores da
                  simulação no momento da contratação
                </>
              }
            />
          </Text>
        </div>

        <If
          condition={!!onFinish}
          renderIf={
            <div className="price-simulator__download-holder">
              <Button
                className={classnames({
                  "-loading": isLoading,
                })}
                id="price-simulator__end-simulation-button"
                onClick={() => {
                  formProps.setValue("shouldSendToLead", true)
                  onFinish()
                  prepareToTrack()
                }}
                aria-busy={isLoading}
                type="button"
                fluid
              >
                Finalizar simulação
              </Button>
            </div>
          }
        />
      </div>
    )
  }
)

FinancingSettings.displayName = "FinancingSettings"

FinancingSettings.propTypes = {
  onFinish: PropTypes.func,
  stepData: PropTypes.object.isRequired,
  formProps: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  requestNewSimulation: PropTypes.func.isRequired,
  isDealer: PropTypes.bool,
  onModalOpen: PropTypes.func.isRequired,
  installments: PropTypes.array.isRequired,
  setSimulationSuccess: PropTypes.func,
}

export default FinancingSettings
