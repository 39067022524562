export const sortOptions = [
  {
    value: "featured",
    label: "destaques",
  },
  {
    value: "descendingPrice",
    label: "preço (decrescente)",
  },
  {
    value: "ascendingPrice",
    label: "preço (crescente)",
  },
  {
    value: "year",
    label: "ano mais novo",
  },
]
