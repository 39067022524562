import { useState, useContext } from "react"

// Contexts
import { Context } from "contexts/simulation"

const useEditModalContent = ({ getDeal, onClose }) => {
  const [isLoading, setIsLoading] = useState(true)

  const { simulation, setSimulation } = useContext(Context)

  const updateLocalStep = (updatedSimulation) => {
    const data = updatedSimulation?.data
    setSimulation({
      ...data,
      currentFormStep: simulation.currentFormStep,
      nextFormStep: simulation.nextFormStep,
      previousFormStep: simulation.previousFormStep,
    })

    getDeal()

    onClose()
  }

  return {
    isLoading,
    setIsLoading,
    updateLocalStep,
  }
}

export default useEditModalContent
