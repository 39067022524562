import { useRef, useEffect } from "react"

function useLatestPropsRef(value) {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}

export default useLatestPropsRef
