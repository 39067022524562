// Components
import { Text } from "@mobi/ds"
import { Icon } from "@vehicles/components"
import If from "@mobi/components/If"

//Style
import "./index.scss"

const NextSteps = ({ isDealer }) => {
  const agent = isDealer ? "lojista" : "especialista"

  return (
    <div className="nextSteps">
      <Text className="nextSteps__title" as="h3" size="sm" weight="800">
        próximos passos
      </Text>
      <div className="nextSteps__steps">
        <div className="nextSteps__item">
          <div className="nextSteps__tracking-stages">
            <Icon className="nextSteps__icon" id="icon-success-check" />
            <div className="nextSteps__color-bar"></div>
          </div>
          <div className="nextSteps__text">
            <Text className="nextSteps__paragraph" as="p" size="sm">
              simule com as melhores condições
            </Text>
          </div>
        </div>
        <div className="nextSteps__item">
          <div className="nextSteps__tracking-stages">
            <span className="nextSteps__tracking-stages-solid"></span>
            <div className="nextSteps__dashed-bar"></div>
          </div>
          <div className="nextSteps__text">
            <Text className="nextSteps__paragraph" as="p" size="sm">
              <strong>{`entre em contato com o ${agent}`}</strong>
            </Text>
          </div>
        </div>
        <div className="nextSteps__item">
          <div className="nextSteps__tracking-stages">
            <span className="nextSteps__tracking-stages-dashed"></span>
            <div className="nextSteps__dashed-bar"></div>
          </div>
          <div className="nextSteps__text">
            <If
              condition={isDealer}
              renderIf={
                <Text className="nextSteps__paragraph" as="p" size="sm">
                  a partir da sua simulação, receba uma proposta com o lojista
                </Text>
              }
              renderElse={
                <Text className="nextSteps__paragraph" as="p" size="sm">
                  negocie uma proposta personalizada
                </Text>
              }
            />
          </div>
        </div>
        <div className="nextSteps__item">
          <div className="nextSteps__tracking-stages">
            <span className="nextSteps__tracking-stages-dashed"></span>
          </div>
          <div className="nextSteps__text">
            <Text className="nextSteps__paragraph" as="p" size="sm">
              receba o financiamento do seu carro
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NextSteps
