import { useCallback, useEffect } from "react"
import { Text } from "@mobi/ds"

//Hooks
import useTimer from "./useTimer"

//Utils
import getSecondsFormatted from "@mobi/utils/formatters/time"
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"

// Components
import { Button } from "@mobi/ds"

const secondsToDecrease = 120

const Timer = ({ onResendClick, keyToPersistExpirationTime = "" }) => {
  const getIsPersistedTimestampValid = useCallback(() => {
    if (!keyToPersistExpirationTime) {
      return false
    }

    const persistedExpirationTimestamp = getFromSessionStorage({
      name: keyToPersistExpirationTime,
    })

    const secondsBetweenPersistedAndNow =
      (Date.now() - persistedExpirationTimestamp) / 1000

    const isPersistedTimestampValid =
      !!persistedExpirationTimestamp &&
      secondsBetweenPersistedAndNow < secondsToDecrease

    return isPersistedTimestampValid
  }, [keyToPersistExpirationTime])

  function getCurrentTime() {
    const persistedExpirationTimestamp = getFromSessionStorage({
      name: keyToPersistExpirationTime,
    })

    const isPersistedTimestampValid = getIsPersistedTimestampValid()

    const currentTime = isPersistedTimestampValid
      ? new Date(Number(persistedExpirationTimestamp))
      : new Date()
    currentTime.setSeconds(currentTime.getSeconds() + secondsToDecrease)

    return currentTime
  }

  const { seconds, minutes, isRunning, restart } = useTimer({
    currentTime: getCurrentTime(),
  })

  function handleRestartTimer() {
    const newTime = new Date()
    newTime.setSeconds(newTime.getSeconds() + secondsToDecrease)

    if (keyToPersistExpirationTime) {
      saveOnSessionStorage({
        name: keyToPersistExpirationTime,
        value: Date.now(),
      })
    }

    restart(newTime)
    onResendClick()
  }

  useEffect(() => {
    const isPersistedTimestampValid = getIsPersistedTimestampValid()

    if (!isPersistedTimestampValid && keyToPersistExpirationTime) {
      saveOnSessionStorage({
        name: keyToPersistExpirationTime,
        value: Date.now(),
      })
    }
  }, [getIsPersistedTimestampValid, keyToPersistExpirationTime])

  if (!isRunning) {
    return (
      <Button
        type="button"
        variant="secondary"
        className={"--link"}
        onClick={handleRestartTimer}
      >
        reenviar código
      </Button>
    )
  }

  return (
    <Text as="p" size="md" className="expiration-warning">
      você pode pedir um novo código em
      <strong>
        {" "}
        {minutes}:{getSecondsFormatted(seconds)}
      </strong>
    </Text>
  )
}

export default Timer
