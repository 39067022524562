import { useEffect, useState } from "react"
import classNames from "classnames"

//Components
import { Text } from "@mobi/ds"

//Style
import "./index.scss"

// Icons
import CarIllustration from "images/car-blue-illustration.svg"

const LoaderScreen = () => {
  const [activedText, setActivedText] = useState(0)

  const listText = [
    "aguarde... estamos buscando a melhor oferta para você ;)",
    "estamos analisando suas condições de crédito...",
    "separando a condição ideal para o seu perfil...",
    "pronto ... estamos preparando a melhor oferta para você ;)",
  ]

  useEffect(() => {
    const sliderTimer = setInterval(() => {
      setActivedText((counter) =>
        counter < listText.length - 1 ? counter + 1 : 0
      )
    }, 5000)

    return () => {
      clearInterval(sliderTimer)
    }
  }, [])

  return (
    <div className="loader-screen">
      <div className="loader-screen__container">
        <div className="loader-screen__wrapper-illustration">
          <CarIllustration />
        </div>
        <div className="loader-screen__content">
          <div className="loader-screen__wrapper-text">
            {listText.map((item, index) => {
              return (
                <Text
                  key={item}
                  as="h3"
                  size="xl"
                  className={classNames("loader-screen__slider-text", {
                    "loader-screen__slider-text--actived":
                      index === activedText,
                  })}
                >
                  {item}
                </Text>
              )
            })}
          </div>
          <div className="loader-screen__loader">
            <svg viewBox="25 25 50 50">
              <circle
                className="circle"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoaderScreen
