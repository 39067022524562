//Components
import { Text } from "@mobi/ds"
import { Icon } from "@vehicles/components"

//Uteis
import If from "@mobi/components/If"

import ContactCorbanDigital from "../ContactCorbanDigital"
import Contacts from "../Contacts"

const Feedback = ({ seller, sellerName, isDealer }) => {
  const phone = seller?.contacts?.find((item) => item.kind === "PHONE")?.value
  const whatsapp = seller?.contacts?.find(
    (item) => item.kind === "WHATSAPP"
  )?.value

  return (
    <If
      condition={isDealer}
      renderIf={
        <>
          <div className="title">
            <Icon id="icon-success-check" />
            <Text as="h1" size="xxl">
              <strong role="text">
                Você está quase lá!
                <If
                  condition={phone}
                  renderIf={`Agora entre em contato com a loja`}
                  renderElse={`Agora você já pode procurar a loja`}
                />{" "}
                {<span className="seller-name">{sellerName}</span>}
              </strong>
            </Text>
          </div>
          <Contacts phone={phone} whatsapp={whatsapp} sellerName={sellerName} />
        </>
      }
      renderElse={
        <ContactCorbanDigital
          sellerName={sellerName}
          sellerPhone={phone}
          sellerWhatsapp={whatsapp}
        />
      }
    />
  )
}

export default Feedback
