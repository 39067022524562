const getVehicleLabels = (vehicleNameAndDescription = "") => {
  const components = vehicleNameAndDescription
    .split(/(\d\.\d)/)
    .map((part) => part.trim())
    .filter((part) => part)
  const name = components.shift() || ""
  const description = components.join(" ")
  return { name, description }
}

export default getVehicleLabels
