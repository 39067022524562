// Components
import { Text } from "@mobi/ds"
import Img from "components/Img"

//Style
import "./index.scss"

const Recommender = () => {
  return (
    <>
      <div className="container-heading">
        <Text as="h2" size="lg">
          financiando com o Itaú você pode contar com mais benefícios
        </Text>
      </div>
      <div className="container-recommender">
        <div className="card">
          <div className="header">
            <Img
              className="image"
              file="conectcar-image"
              alt="Logo ConectCar"
            />
          </div>
          <div className="body">
            <Text size="md">
              Até 36 mensalidades grátis + 30% OFF nas demais mensalidades
            </Text>
          </div>
        </div>
        <div className="card">
          <div className="header">
            <Img
              className="image"
              file="portoseguro-image"
              alt="Logo Porto Seguro"
            />
          </div>
          <div className="body">
            <Text size="md">
              Até 10 de desconto no seguro para proteger seu carro
            </Text>
          </div>
        </div>
      </div>
    </>
  )
}

export default Recommender
