import { Text } from "@mobi/ds"

// Utils
import { simulation } from "@vehicles/data"
import { currencyToFloat } from "@mobi/utils/formatters/currency"

const { getDownpaymentLabel } = simulation
const DownPaymentSuggestion = ({
  vehicleValue,
  downPayment,
  maxAcceptable,
  minAcceptableDownPayment,
  conditions,
  ariaLive,
}) => {
  const percentage = downPayment / (currencyToFloat(vehicleValue) / 100)

  const label = getDownpaymentLabel({
    ...conditions,
    maxAcceptable,
    minAcceptableDownPayment,
  })

  return (
    <>
      <Text
        className="-disclaimerlabel"
        size="sm"
        aria-live={ariaLive ? "assertive" : "off"}
      >
        {label}
      </Text>
      <div
        className="price-simulator__down-payment-suggestion"
        role="presentation"
      >
        <div
          className="price-simulator__quantity"
          style={{ position: "relative" }}
        >
          <div
            className="price-simulator__bar-value"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <div className="price-simulator__wrapper">
          <p className="price-simulator__label-downpayment -bold">entrada</p>
          <p className="price-simulator__label-total">valor total do veículo</p>
        </div>
      </div>
    </>
  )
}

export default DownPaymentSuggestion
