import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

//Components
import { Stepper, Text, Button, Select, Link } from "@mobi/ds"
import Sender from "components/Sender"
import { Pagination, Modal, Icon } from "@vehicles/components"
import If from "@mobi/components/If"
import Loader from "components/Loader"
import FilterTags from "./components/FilterTags"
import FormTemplate from "./components/FormTemplate"
import VehicleDetails from "./components/VehicleDetails"
import SortTemplate from "./components/SortTemplate"
import FilterTemplate from "./components/FilterTemplate"
import AdCard from "./components/AdCard"

//Style
import "./index.scss"
import "react-input-range/lib/css/index.css"

// Hooks
import useDeal from "./useDeal"
import useMake from "hooks/useMake"

//Data
import { sortOptions } from "./dataContent"

// Utils
import isMobile from "@mobi/utils/browser/isMobile"

const Deal = ({ current }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const { onProductClicked } = useMake()
  const { title } = current

  const {
    sortForm,
    filterForm,
    isLoading,
    isLoadingPlaceholder,
    lastFiltersUsed,
    stepData,
    selectedVehicle,
    currentPage,
    onNext,
    onPrev,
    selectVehicle,
    searchDeals,
    onKmRange,
    onSearchCities,
    setIsLoadingPlaceholder,
    setLastFiltersForm,
    handleOnClearFilters,
    hasUsedFilter,
    mapFormFiltersToSearch,
    handleOnClearFilter,
    formatKmInputValue,
    getDeal,
  } = useDeal()

  const { deals, pages, total } = stepData

  const isEmpty = !(deals && deals.length > 0)

  const onCloseModal = () => {
    if (modalContent === "filter") {
      setLastFiltersForm()
    }

    setIsModalOpened(false)
    setModalContent("")
  }

  const sendSort = (values) => {
    setIsLoadingPlaceholder(true)

    const optionSelected = sortOptions.find(
      (item) => item.label === values.sort
    )
    searchDeals({ sort: optionSelected.value })
    setIsModalOpened(false)
    setModalContent("")
  }

  const Templates = {
    form: () => (
      <FormTemplate
        onCloseModal={onCloseModal}
        setIsLoadingPlaceholder={setIsLoadingPlaceholder}
        getDeal={getDeal}
      />
    ),
    details: () => (
      <VehicleDetails
        selectedVehicle={selectedVehicle}
        selectVehicle={selectVehicle}
        onProductClicked={onProductClicked}
        onClose={onCloseModal}
        onNext={onNext}
      />
    ),
    sort: () => (
      <SortTemplate
        sortForm={sortForm}
        send={sendSort}
        setIsModalOpened={setIsModalOpened}
        setModalContent={setModalContent}
      />
    ),
    filter: ({ asModalContent = false }) => {
      return (
        <FilterTemplate
          asModalContent={asModalContent}
          filterForm={filterForm}
          handleOnClearFilters={handleOnClearFilters}
          formatKmInputValue={formatKmInputValue}
          searchDeals={searchDeals}
          hasUsedFilter={hasUsedFilter}
          setIsLoadingPlaceholder={setIsLoadingPlaceholder}
          mapFormFiltersToSearch={mapFormFiltersToSearch}
          setIsModalOpened={setIsModalOpened}
          setModalContent={setModalContent}
          onKmRange={onKmRange}
          onSearchCities={onSearchCities}
          onCloseModal={onCloseModal}
        />
      )
    },
  }

  const QuantityFound = ({ total }) => (
    <If
      condition={parseInt(total) > 0}
      renderIf={
        <If
          condition={total < 2}
          renderIf={<>{total} carro encontrado</>}
          renderElse={<>{total} carros encontrados</>}
        />
      }
      renderElse={<>Nenhum resultado encontrado</>}
    />
  )

  const onClickPagination = (newPage) => {
    searchDeals({ page: newPage }, newPage)
    setIsLoadingPlaceholder(true)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <If condition={isLoading} renderIf={<Loader />} />
      <Modal isOpened={isModalOpened} onCloseClick={onCloseModal}>
        {modalContent && Templates[modalContent]({ asModalContent: true })}
      </Modal>
      <div className="step step--deal">
        <div className="step__container">
          <div className="step__headline">
            <br />
            <Stepper title={title} index={2} max={4} />
            <br />
            <Text className="step__title" as="h1" size="xxl">
              <strong>Escolha um veículo</strong>
            </Text>
            <Text size="md">
              Selecione o veículo que mais lhe agrada dentro do nosso catálogo:
            </Text>
            <If
              condition={isMobile}
              renderIf={
                <div>
                  <div className="step__deck">
                    <Button
                      type="button"
                      id="step__edit-button"
                      onClick={() => {
                        setModalContent("form")
                        setIsModalOpened(true)
                      }}
                    >
                      <Icon id="icon-edit" />
                      Editar dados do veículo
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setModalContent("sort")
                        setIsModalOpened(true)
                      }}
                    >
                      <Icon id="icon-sort" />
                      ordenar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setModalContent("filter")
                        setIsModalOpened(true)
                      }}
                    >
                      <Icon id="icon-filter" />
                      filtrar
                    </Button>
                  </div>

                  <If
                    condition={hasUsedFilter()}
                    renderIf={
                      <div className="step__mobile-filters-container">
                        <FilterTags
                          lastFiltersUsed={lastFiltersUsed}
                          handleOnClearFilter={handleOnClearFilter}
                        />

                        <Link
                          className="step__clear-filters"
                          href={""}
                          onClick={handleOnClearFilters}
                        >
                          limpar filtros
                        </Link>
                      </div>
                    }
                  />
                </div>
              }
              renderElse={
                <>
                  <br />
                  <Button
                    variant="secondary"
                    id="step__edit-button"
                    type="button"
                    onClick={() => {
                      setModalContent("form")
                      setIsModalOpened(true)
                    }}
                  >
                    Editar dados do veículo
                  </Button>
                </>
              }
            />
          </div>

          <div className="step__control">
            <Text
              as="p"
              size={isMobile ? "md" : "xl"}
              className={classNames("step__quantity-found", {
                "step__quantity-found--shimmer-animation": isLoadingPlaceholder,
              })}
              weight={700}
            >
              <If
                condition={!isLoadingPlaceholder}
                renderIf={<QuantityFound total={total} />}
              />
            </Text>
            <If
              condition={!isMobile}
              renderIf={
                <div className="step__sorter">
                  <form onSubmit={(f) => f}>
                    <Select
                      name="sort"
                      label="ordenar"
                      className="deal-input"
                      minSearch={2}
                      options={sortOptions}
                      onChange={(item) => {
                        setIsLoadingPlaceholder(true)
                        searchDeals({ sort: item?.value })
                        sortForm.setValue("sort", item.label)
                      }}
                      onBlur={(e) => {
                        setIsLoadingPlaceholder(true)
                        searchDeals({ sort: "featured" })
                        sortForm.setValue("sort", "destaques")
                      }}
                      required={false}
                      control={sortForm.control}
                      formProps={sortForm}
                    />
                  </form>
                </div>
              }
            />
          </div>

          <div className="step__board">
            {Templates["filter"]({ asModalContent: false })}
            <div className="step__main">
              <If
                condition={isLoadingPlaceholder}
                renderIf={
                  <div className="step__grid">
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                    <div className="step__card step__card--shimmer-animation"></div>
                  </div>
                }
                renderElse={
                  <div
                    className={classNames("step__grid", {
                      "step__grid--empty": isEmpty,
                    })}
                  >
                    <If
                      condition={!isEmpty}
                      renderIf={
                        <AdCard
                          deals={deals}
                          selectedVehicle={selectedVehicle}
                          onProductClicked={onProductClicked}
                          onNext={onNext}
                          selectVehicle={selectVehicle}
                          setModalContent={setModalContent}
                          setIsModalOpened={setIsModalOpened}
                        />
                      }
                      renderElse={
                        <div className="step__empty">
                          <StaticImage
                            src="../../../../images/empty.png"
                            alt=""
                          />
                          <Text as="p" size="md" weight={700}>
                            Não foram encontrados veículos com essas
                            características
                          </Text>
                        </div>
                      }
                    />
                  </div>
                }
              />
              <If
                condition={!isEmpty}
                renderIf={
                  <Pagination
                    pages={pages}
                    currentPage={currentPage - 1}
                    onSelectPage={({ target }) => {
                      const newPage = target?.value
                        ? parseInt(target?.value) + 1
                        : 1
                      onClickPagination(newPage)
                    }}
                    onPrev={() => {
                      if (currentPage > 0) {
                        const newPage = currentPage - 1
                        onClickPagination(newPage)
                      }
                    }}
                    onNext={() => {
                      if (currentPage < pages) {
                        const newPage = currentPage + 1
                        onClickPagination(newPage)
                      }
                    }}
                  />
                }
              />
              <Sender
                isNextDisabled={!selectedVehicle.dealID}
                hasPrev={true}
                hasNext={false}
                nextLabel="Avançar"
                onPrev={onPrev}
                onNext={onNext}
                className="step__sender"
              />
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  )
}
export default Deal
