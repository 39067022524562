import { useState, useEffect } from "react"

const getSecondsRemaining = (expiration) => {
  const now = new Date().getTime()
  const milliSecondsRemaining = expiration - now
  if (milliSecondsRemaining > 0) {
    const secondsRemaing = milliSecondsRemaining / 1000
    return parseInt(secondsRemaing.toFixed(0))
  }
  return 0
}

const getTimeFromSeconds = (seconds) => {
  const totalSeconds = Math.ceil(seconds)

  return {
    seconds: Math.floor(totalSeconds % 60),
    minutes: Math.floor((totalSeconds % (60 * 60)) / 60),
  }
}

const useTimer = ({ currentTime }) => {
  const [expirationTimestamp, setExpirationTimestamp] = useState(currentTime)
  const [isRunning, setIsRunning] = useState(true)
  const [seconds, setSeconds] = useState(
    getSecondsRemaining(expirationTimestamp)
  )
  const delay = 1000

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsRemaining = getSecondsRemaining(expirationTimestamp)
      setSeconds(secondsRemaining)
      if (secondsRemaining <= 0) {
        handleExpire()
      }
    }, delay)

    return () => {
      clearInterval(interval)
    }
  }, [expirationTimestamp])

  const handleExpire = () => {
    setIsRunning(false)
  }

  const restart = (newExpirationTimestamp) => {
    setIsRunning(false)
    setIsRunning(true)
    setExpirationTimestamp(newExpirationTimestamp)
    setSeconds(getSecondsRemaining(newExpirationTimestamp))
  }

  return {
    ...getTimeFromSeconds(seconds),
    restart,
    isRunning,
  }
}

export default useTimer
