//Components
import { Icon } from "@vehicles/components"

//Style
import "./index.scss"

// Utils
import numberFormatter from "@mobi/utils/formatters/number"

const FilterTags = ({ lastFiltersUsed, handleOnClearFilter }) => {
  const filtersTags = [
    {
      hasValue: !!lastFiltersUsed.maxKm,
      content: `Até ${numberFormatter({
        value: lastFiltersUsed.maxKm || "",
        decimals: 0,
      })} Km`,
      formName: "kilometers",
      searchName: "maxKm",
    },
    {
      hasValue: !!lastFiltersUsed.plateFinal,
      content: `Placas com final: ${(lastFiltersUsed.plateFinal || "")
        .split("|")
        .join(", ")}`,
      formName: "plateFinal",
      searchName: "plateFinal",
    },
    {
      hasValue: !!lastFiltersUsed.newVehicles,
      content: "0 Km",
      formName: "isNewVehicle",
      searchName: "newVehicles",
    },
    {
      hasValue: !!lastFiltersUsed.city,
      content: lastFiltersUsed.city,
      formName: "city",
      searchName: "city",
    },
    {
      hasValue: !!lastFiltersUsed.initialPrice,
      content: `De R$ ${numberFormatter({
        value: lastFiltersUsed.initialPrice || "",
      })}`,
      formName: "initialPrice",
      searchName: "initialPrice",
    },
    {
      hasValue: !!lastFiltersUsed.finalPrice,
      content: `Até R$ ${numberFormatter({
        value: lastFiltersUsed.finalPrice || "",
      })}`,
      formName: "finalPrice",
      searchName: "finalPrice",
    },
  ]

  return (
    <div className="filter-tags__container">
      {filtersTags.map(({ hasValue, content, formName, searchName }) =>
        hasValue ? (
          <div key={formName} className="filter-tag">
            <div className="filter-tag__content">{content}</div>

            <button
              className="filter-tag__clear"
              onClick={(e) => {
                handleOnClearFilter(e, formName, searchName)
              }}
            >
              <Icon className="filter-tag__clear-icon" id="icon-close" />
            </button>
          </div>
        ) : null
      )}
    </div>
  )
}

export default FilterTags
