import { useRef, useState } from "react"
import * as debounce from "lodash.debounce"

const useFinancingSettings = ({
  stepData,
  formProps,
  requestNewSimulation,
}) => {
  const [resetCurrencyField, setResetCurrencyField] = useState(0)
  const [ariaLive, setAriaLive] = useState(false)

  const {
    minAcceptableDownPayment,
    highChanceOfApprovalDownPayment: highChanceValue = false,
    vehicleValue,
  } = stepData

  const maxAcceptable = Number(((vehicleValue / 100) * 90).toFixed(0))
  const downPayment = formProps.watch("downPayment")

  const conditions = {
    isMinAcceptableDownPayment: downPayment === minAcceptableDownPayment,
    isLessThanMin: downPayment < minAcceptableDownPayment,
    hasHighChance: highChanceValue && downPayment >= highChanceValue,
    needAnalysis:
      highChanceValue &&
      downPayment < highChanceValue &&
      downPayment > minAcceptableDownPayment,
    isMaxDownPayment: downPayment === maxAcceptable,
    isBiggerThanMax: downPayment > maxAcceptable,
  }

  const debouncedRequestNewSimulation = useRef(
    debounce(({ downPayment, isValidValue, downPaymentWasChanged }) => {
      if (isValidValue && downPaymentWasChanged) {
        requestNewSimulation(downPayment)
      }
    }, 1300)
  ).current

  const getAcceptableDownPayment = (currentDownPaymentValue) => {
    const isLessThanMinAcceptable =
      currentDownPaymentValue < minAcceptableDownPayment

    const isMoreThanMaxAcceptable = currentDownPaymentValue > maxAcceptable

    if (isLessThanMinAcceptable) {
      return minAcceptableDownPayment
    }

    if (isMoreThanMaxAcceptable) {
      return maxAcceptable
    }

    return currentDownPaymentValue
  }

  return {
    conditions,
    resetCurrencyField,
    setResetCurrencyField,
    downPayment,
    minAcceptableDownPayment,
    maxAcceptable,
    ariaLive,
    setAriaLive,
    debouncedRequestNewSimulation,
    getAcceptableDownPayment,
  }
}

export default useFinancingSettings
